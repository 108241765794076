import {Cellule} from "../../../types/cellule";
import React, {useEffect, useMemo, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {IconeLoader} from "../../../components/icone/icone";
import {Accordion, Box, Modal, Checkbox, Group, Popover, Tooltip, Switch} from "@mantine/core";
import {User} from "../../../types/user";
import {Organisation} from "../../../types/organisation";
import "./modale_utilisateurs.scss"
import {
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, Row, SortingState,
    useReactTable
} from "@tanstack/react-table";
import {Tableau} from "../../../components/tableau/Tableau";
import {All_permissions, permission} from "../../../types/permissions";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Hub} from "../../../types/hub";
import {Modale_alertes_users_cellules} from "../../../components/Modales/modale_alertes_users/modale_alertes_users_cellules";
import {texte_est_inclus} from "../../../services/GestionTextes";
import {estDesactivee} from "./Orga_admin";
import {getAffichageNom} from "../../../services/UserService";
import {IconeEye} from "../../../components/icone/solid_react/eye";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeClock} from "../../../components/icone/solid_react/clock";
import {IconeUsers} from "../../../components/icone/solid_react/users";
import {IconeUserPlus} from "../../../components/icone/solid_react/user-plus";
import {IconeUserMinus} from "../../../components/icone/solid_react/user-minus";
import {IconeUserGear} from "../../../components/icone/solid_react/user-gear";
import {setPasApasEtat} from "../../../store/pasApasSlice";
import {useDispatch} from "react-redux";

export function Modale_utilisateurs({
                                        organisation,
                                        setOrganisation
                                    }: { organisation: Organisation, setOrganisation: React.Dispatch<Organisation | null> }) {
    const [opened, handlers] = useDisclosure(false); //modale

    const [utilisateurs, set_utilisateurs] = useState<User[] | null>(null);

    const [sorting, setSorting] = React.useState<SortingState>([])

    const [hide_modale, set_hide_modale] = useState(false);

    const [rowSelection, setRowSelection] = React.useState<Record<string, boolean>>({})


    const [btn_delete_loading, setBtn_delete_loading] = useState(false);

    const columnHelper = createColumnHelper<User>()

    const dispatch = useDispatch()

    const columns = useMemo(() => [
        columnHelper.accessor('email', {
            id: 'Selection',
            header: ({table}) => (
                <span className={"aligne_left"}>
                    {'\u00A0'}
                    <Checkbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </span>

            ),
            cell: ({row}) => (
                <div className="px-1">
                    <Checkbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),

            enableColumnFilter: false,
            enableSorting: false
        }),
        columnHelper.accessor('nom_affichage', {
            id: "Nom d'affichage",
            header: () => 'Nom d\'affichage',
            cell: info => <>
                <strong className={"th_mobile"}>Nom d'affichage : </strong>
                {info.getValue()}
            </>,
            enableColumnFilter: true
        }),
        columnHelper.accessor('nom_famille', {
            id: "Nom de famille",
            header: () => 'Nom de famille',
            cell: info => <>
                <strong className={"th_mobile"}>Nom de famille</strong>
                {info.getValue()}
            </>,
            enableColumnFilter: true
        }),
        columnHelper.accessor('email', {
            id: 'Email',
            header: () => 'Email',
            cell: info =>
                info.getValue() != null && (
                    <span className={"aligne_left"}>
                    <strong className={"th_mobile"}>Email : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor('tel', {
            id: 'Tél',
            header: () => 'Tél',
            cell: info => <>
                <strong className={"th_mobile"}>Tél : </strong>
                {info.getValue()}
            </>,
            enableColumnFilter: true
        }),
        columnHelper.accessor('roles_organisation', {
            id: 'Permissions',
            header: () => 'Permissions',
            cell: info => <>
                <strong className={"th_mobile"}>Permissions : </strong>
                <Popover width={200} position="bottom" withArrow shadow="md">
                    <Popover.Target>
                        <button className={"sec"}><IconeEye/></button>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <span className={"flex-col"}>
                            {info.getValue()?.map(i => All_permissions.filter(ap => ap.slug === i)[0] ? <li>{All_permissions.filter(ap => ap.slug === i)[0].nom + " "}</li> : <li>{i + " "}</li>)}
                        </span>
                    </Popover.Dropdown>
                </Popover>

            </>,
            filterFn: (row: Row<User>,
                       columnId: string,
                       filterValue) => {
                if (row.getValue(columnId)) {
                    let orga: string[] = row.getValue(columnId);
                    let texte: string = "";
                    orga.map(o => texte += o + " ");
                    return texte_est_inclus(texte, filterValue)
                }
                return false;
            },
            enableColumnFilter: true
        }),
        columnHelper.accessor('statut', {
            id: 'Statut',
            header: () => '',
            cell: info => {
                let icone = <p>{info.getValue()}</p>;
                if (info.getValue() === "Actif") icone = <IconeCheck/>
                if (info.getValue() === "Non validé") icone = <IconeClock/>
                return (<>
                        <strong className={"th_mobile"}>Statut : </strong>
                        <Tooltip label={"Statut : " + info.getValue()}>
                            <p>{icone}</p>
                        </Tooltip>

                    </>
                )},
            enableColumnFilter: false
        }),
    ], [])

    const table = useReactTable<User>({
        data: utilisateurs ? utilisateurs : [],
        columns: columns,
        state: {
            sorting,
            rowSelection,
        },
        enableRowSelection: true, //enable row selection for all rows
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        enableFilters: true,
        enableColumnFilters: true
    })

    useEffect(() => {
            table.setPageSize(5);
            ajax.get("/organisations/" + organisation.id + "/utilisateurs").then(response => {
                set_utilisateurs(response.data.utilisateurs)

            }).catch(error => {
            })

    }, [])

    useEffect(() => {
        if (opened && utilisateurs !== null)
        {
            dispatch(setPasApasEtat("modale_user_orga_loaded"))
        }

    }, [opened]);

    let selectedRows = table.getSelectedRowModel().rows.map(row => {
        return row.original;
    });

    function handleSupprimerUsers() {
        if (window.confirm("ATTENTION : Vous vous appretez à supprimer de l'organisation les utilisateurs suivant :\n" +
            selectedRows.map(u => "\n - " + getAffichageNom(u) + ' (' + u.email + ')') +
            "\n\nEtes vous sur de vouloir poursuivre ?"))
        {
            setBtn_delete_loading(true);
            ajax.delete("/organisations/" + organisation.id + "/utilisateurs", {
                data: {
                    "utilisateurs": selectedRows
                }
            }).then(response => {
                if (utilisateurs) set_utilisateurs(utilisateurs?.filter(u => {
                    let res = true;
                    selectedRows.map(su => {
                        if (su.email === u.email) res = false;
                    })
                    return res
                }));

                setBtn_delete_loading(false)
            }).catch(error => {
                setBtn_delete_loading(false)
            })
        }



    }

    return (
        <>
            <button className={"sec"} onClick={handlers.open}><IconeUsers/>Utilisateurs</button>


            <Modal opened={opened} onClose={handlers.close} title="Utilisateurs de cette organisation"
                   className={hide_modale ? "modal_hide" : ""}>

                {utilisateurs === null ? (
                    <div className={"en-ligne"}><IconeLoader /> Chargement...</div>
                ) : (

                    <div className={"modale_orga_utilisateurs"}>

                        <div className={"en-ligne spacebetween"}>
                            <div className={"en-ligne"}>
                                <Modale_utilisateurs_ajouter orga={organisation} setHide={set_hide_modale} utilisateurs={utilisateurs} setUtilisateurs={set_utilisateurs}/>
                                <Modale_alertes_users_cellules organisation_id={organisation.id} parent_set_hide={set_hide_modale} />
                            </div>

                            <div className={"en-ligne a_droite"}>
                                <Modale_utilisateurs_permissions utilisateurs={utilisateurs}
                                                                 selectedUtilisateurs={selectedRows}
                                                                 setUtilisateurs={set_utilisateurs}
                                                                 orga={organisation}
                                                                 setHide={set_hide_modale}/>
                                <Loading_button is_loading={btn_delete_loading} disabled={Object.keys(selectedRows).length === 0} onClick={handleSupprimerUsers}><IconeUserMinus/> Retirer de l'organisation
                                </Loading_button>
                            </div>
                        </div>


                        <Tableau table={table} donnees={utilisateurs}/>


                    </div>


                )}


            </Modal>
        </>

    )
}


function Modale_utilisateurs_permissions({
                                             selectedUtilisateurs,
                                             setUtilisateurs, utilisateurs, setHide, orga
                                         }: {
    selectedUtilisateurs: User[],
    utilisateurs: User[],
    setUtilisateurs: React.Dispatch<User[] | null>,
    orga: Organisation,
    setHide: (etat: boolean) => void
}) {
    const [opened, handlers] = useDisclosure(false); //modale

    const [permissions, setPermissions] = useState<{ permission: permission, checked: boolean }[]>([])

    const [btn_loading, setBtn_loading] = useState(false);

    function handleEnregistrer() {
        setBtn_loading(true);
        ajax.put("/organisations/" + orga.id + "/permissions", {
                "utilisateurs": selectedUtilisateurs.map(u => {
                    u.roles_organisation = permissions.filter(perm => perm.checked).map(perm => perm.permission.slug);
                    return u;
                })
            }
        ).then(response => {
            console.log(selectedUtilisateurs.map(u => u.roles_organisation))
            setUtilisateurs(utilisateurs.map(u => {
                selectedUtilisateurs.map(su => {
                    if (su.id === u.id) {
                        u.roles_organisation = permissions.filter(perm => perm.checked).map(perm => perm.permission.slug);
                    }
                })
                return u;
            }))
            handleClose()
            setBtn_loading(false);
        }).catch(error => {
            setBtn_loading(false);
        })

    }

    const dispatch = useDispatch()

    function handleOpen() {

        let perms = selectedUtilisateurs.at(0)?.roles_organisation


        if (perms === undefined) alert("Erreur, utilisateur #0 undefined.");

        let ask = false;
        let exit = false;
        selectedUtilisateurs.map(u => {
            if (!ask && JSON.stringify(u.roles_organisation) !== JSON.stringify(perms)) {
                if (!window.confirm("Attention : les permissions des utilisateurs selectionnés sont actuellement différentes. Si vous continuez, elles seront remises à 0.")) {
                    exit = true;
                }
                ask = true;
            }
        })
        if (exit) return;
        if (ask) perms = [];

        if (perms !== undefined) setPermissions(All_permissions.map(_perm => {
            return {
                "permission": _perm,
                "checked": perms ? perms.includes(_perm.slug) : false,
            }
        }));

        handlers.open();
        setHide(true);
        dispatch(setPasApasEtat("modale_permissions_tout_users_opened"))
    }

    function handleClose() {
        handlers.close();
        setHide(false);
    }

    return (
        <>
            <button onClick={handleOpen} id={"btn_gerer_les_permissions"} disabled={Object.keys(selectedUtilisateurs).length === 0}><IconeUserGear/>Gérer
                les permissions
            </button>


            <Modal opened={opened} onClose={handleClose} title="Permissions">

                <span>
                    Vous éditez les permissions pour {Object.keys(selectedUtilisateurs).length === 1 ? "l'utilisateur" : "les utilisateurs"} :
                    <div className={"en-ligne"}>{selectedUtilisateurs.map(u => (
                        <>
                            <span className={"inline-tag"}>
                                {getAffichageNom(u)} ({u.email})
                            </span>
                            {' '}
                        </>

                    ))}
                    </div>
                </span>

                <div className={"flex-row no-wrap"}>
                    <div className={"form_permissions"}>
                        Permissions d'accès
                        {permissions.filter(p => p.permission.categorie === "Acces").map(perm => (
                            // <div>{perm} : {permissions.includes?(perm) ? "checked" : ""}</div>
                            <Switch label={perm.permission.nom}
                                      description={perm.permission.descr}
                                      checked={perm.checked}
                                      onChange={event => setPermissions(permissions.map(_perm => {
                                          if (_perm.permission.slug === perm.permission.slug) _perm.checked = event.target.checked;
                                          return _perm;
                                      }))}
                            />
                        ))}
                    </div>
                    <div className={"form_permissions"}>
                        Permissions de notifications
                        {permissions.filter(p =>  ["Notifications", "Cycle"].includes(p.permission.categorie)).map(perm => (
                            // <div>{perm} : {permissions.includes?(perm) ? "checked" : ""}</div>
                            <Switch label={perm.permission.nom}
                                      description={perm.permission.descr}
                                      checked={perm.checked}
                                      onChange={event => setPermissions(permissions.map(_perm => {
                                          if (_perm.permission.slug === perm.permission.slug) _perm.checked = event.target.checked;
                                          return _perm;
                                      }))}
                            />
                        ))}
                        {permissions.filter(p => p.permission.categorie === "Rapport").map(perm => (
                            // <div>{perm} : {permissions.includes?(perm) ? "checked" : ""}</div>
                            <Switch label={perm.permission.nom}
                                    description={perm.permission.descr}
                                    checked={perm.checked}
                                    onChange={event => setPermissions(permissions.map(_perm => {
                                        if (_perm.permission.categorie === "Rapport")
                                        {
                                            if (_perm.permission.slug === perm.permission.slug) _perm.checked = event.target.checked;
                                            else _perm.checked = false;
                                        }
                                        return _perm;
                                    }))}
                            />
                        ))}
                    </div>
                </div>



                <Loading_button onClick={handleEnregistrer} is_loading={btn_loading}>Valider</Loading_button>


            </Modal>
        </>

    )
}

function Modale_utilisateurs_ajouter({setHide, orga, utilisateurs, setUtilisateurs}: {
    orga: Organisation, setHide: (etat: boolean) => void,
    utilisateurs: User[],
    setUtilisateurs: React.Dispatch<User[] | null>,
}) {
    const [opened, handlers] = useDisclosure(false); //modale

    const [btn_loading, setBtn_loading] = useState(false);


    const [garderOuvert, setGarderOuvert] = useState(false);
    const [newUserEmail, setNewUserEmail] = useState("");

    const dispatch = useDispatch();

    function handleEnregistrer() {
        // setBtn_loading(true);
        // ajax.put("/organisations/" + orga.id + "/permissions", {"utilisateurs" : selectedUtilisateurs.map(u => {
        //         u.roles_organisation = permissions.filter(perm => perm.checked).map(perm => perm.nom);
        //         return u;
        //     })}
        // ).then(response => {
        //     console.log(selectedUtilisateurs.map(u => u.roles_organisation))
        //     setUtilisateurs(utilisateurs.map(u => {
        //         selectedUtilisateurs.map(su => {
        //             if (su.id === u.id)
        //             {
        //                 u.roles_organisation = permissions.filter(perm => perm.checked).map(perm => perm.nom);
        //             }
        //         })
        //         return u;
        //     }))
        //     handleClose()
        //     setBtn_loading(false);
        // }).catch(error => {
        //     setBtn_loading(false);
        // })

    }

    function handleOpen() {
        handlers.open();
        setHide(true);
        dispatch(setPasApasEtat("modale_ajout_user_open"))
    }

    function handleClose() {
        handlers.close();
        setHide(false);
    }


    function handleAjouterUser(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setBtn_loading(true);
        ajax.post("/organisations/" + orga.id + "/utilisateurs", {
            "email": newUserEmail,
            "roles_organisation": ["Consulter_donnees"],
        }).then(response => {
            if (!garderOuvert) {
                handleClose();
            }
            setUtilisateurs(utilisateurs.concat(response.data.utilisateur));

            setNewUserEmail("");
            setBtn_loading(false)
        }).catch(error => {
            setBtn_loading(false)
        })

    }

    return (
        <>
            <button id={"btn_ajout_user"} disabled={estDesactivee(orga)} onClick={handleOpen}><IconeUserPlus/>Ajouter un utilisateur
            </button>


            <Modal opened={opened} onClose={handleClose} title="Ajouter un utilisateur">

                <form className={"new_user"} onSubmit={handleAjouterUser}>
                    Renseignez l'adresse email du compte que vous voulez ajouter à l'organisation :
                    <div className={"en-ligne"}>
                        <input type="email" required={true} value={newUserEmail}
                               onChange={e => setNewUserEmail(e.target.value)}
                               placeholder={"email@email.com"}
                        />
                    </div>

                    <p className={"inline-tag"}>Si l'utilisateur n'est pas déjà enregistré sur la plateforme, son compte
                        sera créé et un email lui sera envoyé pour finaliser la création du compte.</p>

                    <div className={"en-ligne spacebetween"}>
                        <Checkbox label={"Plusieurs utilisateurs à ajouter"} checked={garderOuvert}
                                  onChange={e => setGarderOuvert(e.target.checked)}/>
                        <Loading_button onClick={handleEnregistrer} is_loading={btn_loading}><IconeUserPlus/> Ajouter l'utilisateur</Loading_button>
                    </div>


                </form>


            </Modal>
        </>

    )
}