import React, {useEffect, useRef, useState} from 'react';
import './En_direct_organisation.scss'
import '../../Accueil/accueil_organisation/accueil_organisation.scss'
import {Organisation} from "../../../../types/organisation";
import {IconeLoader} from "../../../../components/icone/icone";
import {Zone} from "../../../../types/zone";
import {Carte_mesure} from "../../../../components/carte_mesure/carte_mesure";
import {gps_sonde} from "../../../../types/sonde";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import ajax from "../../../../services/AxiosInterceptor";
import {addZonesToOrganisation} from "../../../../store/organisationSlice";
import {useNavigate} from "react-router-dom";
import env_config from "../../../../env_config";
import {
    dashboardCollapse,
    interfaceData,
    setAcceuilCollapseState, setVueEnDirectSettings_cellules_add, setVueEnDirectSettings_cellules_del,
    setVueEnDirectSettings_zones_add, setVueEnDirectSettings_zones_del,
    vue_En_Direct
} from "../../../../store/interfaceSlice";
import {Carte_mesure_loading} from "../../../../components/carte_mesure/carte_mesure_loading";
import {User} from "../../../../types/user";
import {Loading_div} from "../../../../components/loading_div/loading_div";
import {texte_est_inclus} from "../../../../services/GestionTextes";
import {IconeEyeSlash} from "../../../../components/icone/solid_react/eye-slash";
import {IconeXmark} from "../../../../components/icone/solid_react/xmark";
import {IconeToggleOff} from "../../../../components/icone/solid_react/toggle-off";
import {IconeSquareArrowDownRight} from "../../../../components/icone/solid_react/square-arrow-down-right";
import {IconeEye} from "../../../../components/icone/solid_react/eye";
import {IconeTruckMoving} from "../../../../components/icone/solid_react/truck-moving";
import {Force_refresh} from "../../../../components/force_refresh";


export function En_direct_organisation({organisation, recherche, parametre_ouvert}: { organisation: Organisation, recherche: string, parametre_ouvert:boolean }) {
    const organisations_lastmaj = useSelector<RootState, { lastMiseAJour: number; list: Organisation[]; }>(state => state.organisation)
    const dispatch = useDispatch();
    const user = useSelector<RootState, User>(state => state.user)

    const [loader, setLoader] = useState(<h2><IconeLoader />{organisation.nom}</h2>)
    const ref = useRef<HTMLDivElement>(null);

    const interfaceState = useSelector<RootState, dashboardCollapse[]>(state => state.interface.dashboardCollapse.filter(s => s.collapse_id === "accueil_orga_" + organisation.id))
    let open = interfaceState.length > 0 ? interfaceState[0].open : (organisations_lastmaj.list.length <= 3) //Si > 2 orgas, on les ferme par défaut.
    function orga_est_desactivee() {
        return organisation.desactivation_texte !== null && (!user.roles.includes("ROLE_ADMIN") && !user.roles.includes("ROLE_PRESTATAIRE"))
    }


    useEffect(() => {

        if (orga_est_desactivee()) return;
        // if (!open) return;
        // if (!texte_est_inclus(field_recherche, recherche)) return;
        queryLastInfos();
        const interval_clear = setInterval(() => {
            queryLastInfos();
        }, env_config.refresh_rate);

        return () => {
            clearInterval(interval_clear);
            // console.log("CLEAR " + organisation.id)
        }
    }, [organisations_lastmaj.lastMiseAJour, open, recherche])

    function queryLastInfos() {
        ajax.get('/organisations/' + organisation.id + '/lastInfos')
            .then(response => {

                console.log("Refresh des mesures de l'organisation " + organisation.id + "/" + organisation.nom + " OK")
                dispatch(addZonesToOrganisation({
                    id_orga: organisation.id,
                    zones: response.data.zones,
                    capteurs: response.data.capteurs,
                    hubs: response.data.hubs
                }))
            })
            .catch(error => {
                console.log(error);
                setLoader(
                    <>
                        <h2><IconeXmark/>{organisation.nom}</h2>
                        <p className={"notice"}>{error?.response?.data?.erreur ? error.response.data.erreur : error.message}</p>
                    </>
                )
            });
    }

    let field_recherche = organisation.nom;
    organisation.zones?.map(z => {
        field_recherche += " " + z.nom
        z.cellules_minimum?.map(c => field_recherche += " " + c.nom)
    })
    if (!texte_est_inclus(field_recherche, recherche)) return <></>

    return (
        <>
            {orga_est_desactivee()
                ? (
                    <div className={"accueil_organisation"}>

                        <div className={"en-tete-orga"}>
                            <h2><IconeXmark/>{organisation.nom}</h2>
                            {orga_est_desactivee() && (
                                <span className={"inline-tag"} style={{color: "var(--warning-color)"}}>
                                    <IconeToggleOff/> Organisation désactivée
                                </span>
                            )}
                        </div>
                        <p className={"notice"}>{organisation.desactivation_texte}</p>

                    </div>
                )
                : (
                    <div className={"accueil_organisation"} ref={ref}>
                        <Accueil_organisation_loaded_en_direct organisation={organisation} key={organisation.id} recherche={recherche} open={open} parametre_ouvert={parametre_ouvert}/>
                    </div>
                )
            }

            <Force_refresh receiveForceRefresh={queryLastInfos} />


        </>

    );
}

export function Accueil_organisation_loaded_en_direct({
                                                organisation,
                                                recherche, open, parametre_ouvert
                                            }: { organisation: Organisation, recherche: string, open:boolean, parametre_ouvert:boolean }) {

    const vue_en_direct_settings = useSelector<RootState, vue_En_Direct>(state => state.interface.vue_en_direct)
    function getClassnameEnFonctionDeVueEnDirect()
    {
        let classname = "mesures_" + vue_en_direct_settings.nbe_cartes_par_lignes;
        if (!vue_en_direct_settings.afficher_conditions) classname += " mesures_hide_conditions";
        if (vue_en_direct_settings.mode_compact) classname += " mesures_mode_compact";
        return classname;
    }

    return (
        <>

            <div className={"en-tete-orga"}>

                {(organisation.etat != "New" && organisation.etat != "Active") && (
                    <span className={"inline-tag"} style={{color: "var(--warning-color)"}}>
                        <IconeToggleOff/> Organisation désactivée
                    </span>
                )}
            </div>

            {organisation.desactivation_texte !== null && (
                <p className={"notice"}>{organisation.desactivation_texte}</p>
            )}
            {organisation.etat === "Desac_paiement" && (
                <p className={"notice"}>Votre organisation est désactivée temporairement suite à un défaut de paiement.
                    <br />Les mesures pour lequelles vous avez payés sont accessible mais les nouvelles mesures ne le seront pas.
                    <br />Vous trouverez plus d'infos sur la page "Paiement" dans le menu de gauche.</p>
            )}
            {!organisation.zones && (
                <Loading_div />
            )}
            {(organisation.etat !== "Desac_paiement" && organisation.desactivation_texte === null) && (

                <>
                    {vue_en_direct_settings.afficher_zones ? (

                        <div className={"zones"}>
                            {organisation.zones?.map(zone => {
                                if (zone.nom === "base.config.appareils") return null;

                                let field_recherche = organisation.nom + " " + zone.nom;
                                if (zone.cellules) zone.cellules?.map(c => field_recherche += " " + c.nom + " " + c.extension?.capteur.uuid)
                                else zone.cellules_minimum?.map(c => field_recherche += " " + c.nom)
                                if (!texte_est_inclus(field_recherche, recherche)) return <></>


                                return (
                                    <div className={"zone"} key={zone.id}>

                                        <Accueil_organisation_zone_en_direct zone={zone} organisation={organisation} key={zone.id}
                                                                             recherche={recherche} parametre_ouvert={parametre_ouvert}/>
                                    </div>

                                )

                            })}
                </div>
                    ) : (
                        <div className={"mesures " + getClassnameEnFonctionDeVueEnDirect()}>
                            {organisation.zones?.map(zone => {
                                if (zone.nom === "base.config.appareils") return null;

                                let field_recherche = organisation.nom + " " + zone.nom;
                                if (zone.cellules) zone.cellules?.map(c => field_recherche += " " + c.nom + " " + c.extension?.capteur.uuid)
                                else zone.cellules_minimum?.map(c => field_recherche += " " + c.nom)
                                if (!texte_est_inclus(field_recherche, recherche)) return <></>


                                return (

                                        <Accueil_organisation_cellules_en_direct zone={zone} organisation={organisation} key={zone.id}
                                                                                 recherche={recherche} parametre_ouvert={parametre_ouvert}/>


                                )

                            })}


                        </div>
                    )}
                </>

            )}

        </>
    );
}

export function Accueil_organisation_zone_en_direct({
                                              zone,
                                              organisation,
                                              recherche, parametre_ouvert
                                          }: { zone: Zone, organisation: Organisation, recherche: string, parametre_ouvert:boolean }) {
    const dispatch = useDispatch();
    const interfaceState = useSelector<RootState, dashboardCollapse[]>(state => state.interface.dashboardCollapse.filter(s => s.collapse_id === "accueil_zone_" + zone.id))
    let open = interfaceState.length > 0 ? interfaceState[0].open : true

    function toggleCollapse() {
        return;
        dispatch(setAcceuilCollapseState({
            collapse_id: "accueil_zone_" + zone.id,
            open: !open
        }))
    }

    const navigate = useNavigate();

    const vue_en_direct_settings = useSelector<RootState, vue_En_Direct>(state => state.interface.vue_en_direct)

    function getClassnameEnFonctionDeVueEnDirect()
    {
        let classname = "mesures_" + vue_en_direct_settings.nbe_cartes_par_lignes;
        if (!vue_en_direct_settings.afficher_conditions) classname += " mesures_hide_conditions";
        if (vue_en_direct_settings.mode_compact) classname += " mesures_mode_compact";
        return classname;
    }

    if (zone.cellules?.filter(c => c.extension).length === 0 || zone.cellules_minimum?.filter(c => !c.desactivee).length === 0) return <></>

    let h3zone = (<>{zone.nom}</>)
    if (zone.immatriculation) h3zone = (h3zone = (<> <IconeTruckMoving/> {zone.nom} - {zone.immatriculation}</>))
    if (vue_en_direct_settings.organisations.length > 1) h3zone = <>{organisation.nom} - {h3zone}</>

    let zone_affichee = !vue_en_direct_settings.zones_a_cache.includes(zone.id)

    function hide_zone()
    {
        dispatch(setVueEnDirectSettings_zones_add(zone.id))
    }
    function display_zone()
    {
        dispatch(setVueEnDirectSettings_zones_del(zone.id))
    }

    if (!zone_affichee && !parametre_ouvert) return <></>

    return (
        <>
            {vue_en_direct_settings.afficher_zones && (
                <h3 onClick={toggleCollapse}>
                    <IconeSquareArrowDownRight/> {h3zone}
                    {parametre_ouvert && zone_affichee && (
                        <button onClick={hide_zone}><IconeEye /> Cacher</button>
                    )}
                    {parametre_ouvert && !zone_affichee && (
                        <button onClick={display_zone}><IconeEyeSlash /> Afficher</button>
                    )}
                </h3>
            )}


            {zone_affichee && (
                <div className={"mesures " + getClassnameEnFonctionDeVueEnDirect()}>
                    {!zone.cellules && zone.cellules_minimum?.map(cellule => {
                        if (!cellule.desactivee) { // Désactivée

                            let field_recherche = organisation.nom + " " + zone.nom + " " + cellule.nom
                            if (!texte_est_inclus(field_recherche, recherche)) return <></>



                            return (
                                <Carte_mesure_loading titre={cellule.nom} key={cellule.id} handleClick={() => navigate("/cellule/" + cellule.id)}/>
                            );
                        }
                    })
                    }
                    <Accueil_organisation_cellules_en_direct zone={zone} organisation={organisation} recherche={recherche} parametre_ouvert={parametre_ouvert} />

                </div>
            )}





        </>
    );

}


export function Accueil_organisation_cellules_en_direct({
                                                        zone,
                                                        organisation,
                                                        recherche, parametre_ouvert
                                                    }: { zone: Zone, organisation: Organisation, recherche: string, parametre_ouvert:boolean }) {


    const dispatch = useDispatch();
    const vue_en_direct_settings = useSelector<RootState, vue_En_Direct>(state => state.interface.vue_en_direct)
    const navigate = useNavigate();
    const interfaceState = useSelector<RootState, dashboardCollapse[]>(state => state.interface.dashboardCollapse.filter(s => s.collapse_id === "accueil_zone_" + zone.id))
    let open = interfaceState.length > 0 ? interfaceState[0].open : true
    function hide_cellule(cellule_id:number)
    {
        dispatch(setVueEnDirectSettings_cellules_add(cellule_id))
    }
    function display_cellule(cellule_id:number)
    {
        dispatch(setVueEnDirectSettings_cellules_del(cellule_id))
    }

    return (

        <>
            {zone.cellules?.map(cellule => {
                if (cellule.extension) {

                    let field_recherche = organisation.nom + " " + zone.nom + " " + cellule.nom + " " + cellule.extension.capteur.uuid
                    if (!texte_est_inclus(field_recherche, recherche)) return <></>



                    let cellule_affichee = !vue_en_direct_settings.cellules_a_cache.includes(cellule.id)
                    // if (!vue_en_direct_settings.cellules_a_cache.includes(cellule.id)) return <></>

                    return (
                        <>
                            {!parametre_ouvert && cellule_affichee && (
                                <Carte_mesure titre={cellule.nom}
                                              sonde={cellule.sonde_type}
                                              texte={cellule.last_mesures?.mesure}
                                    // key={cellule.id} handleClick={() => dispatch(setCapteurModale({"capteur": cellule, "organisation": organisation}))}
                                              key={cellule.id}
                                              handleClick={() => navigate("/cellule/" + cellule.id)}
                                              conditions={cellule.statut_actuel?.statut.can_use_alertes ? cellule.conditions : []}
                                              afficherTypeSiPasIcone={true}
                                              affichage_vrai={cellule.affichage_vrai}
                                              affichage_faux={cellule.affichage_faux}
                                              afficherEtatExtension={true}
                                              extension={cellule.extension}
                                              restartTitreAnim={open ? "oui" : "non"}
                                              afficherCondition={true}
                                              commentaire_a_valider={!!cellule.commentaire_a_valider}
                                />
                            )}
                            {parametre_ouvert && cellule_affichee && (
                                <Carte_mesure titre={cellule.nom}
                                              sonde={cellule.sonde_type}
                                              texte={cellule.last_mesures?.mesure}
                                    // key={cellule.id} handleClick={() => dispatch(setCapteurModale({"capteur": cellule, "organisation": organisation}))}
                                              key={cellule.id}
                                              handleClick={() => hide_cellule(cellule.id)}
                                              conditions={cellule.statut_actuel?.statut.can_use_alertes ? cellule.conditions : []}
                                              afficherTypeSiPasIcone={true}
                                              affichage_vrai={cellule.affichage_vrai}
                                              affichage_faux={cellule.affichage_faux}
                                              afficherEtatExtension={true}
                                              extension={cellule.extension}
                                              commentaire_a_valider={!!cellule.commentaire_a_valider}
                                              restartTitreAnim={open ? "oui" : "non"}
                                              afficherCondition={true}
                                              bouton_icone={"eye"}
                                              bouton_onclick={() => hide_cellule(cellule.id)}
                                />
                            )}
                            {parametre_ouvert && !cellule_affichee && (
                                <Carte_mesure titre={cellule.nom}
                                              sonde={cellule.sonde_type}
                                              texte={cellule.last_mesures?.mesure}
                                    // key={cellule.id} handleClick={() => dispatch(setCapteurModale({"capteur": cellule, "organisation": organisation}))}
                                              key={cellule.id}
                                              handleClick={() => display_cellule(cellule.id)}
                                              conditions={cellule.statut_actuel?.statut.can_use_alertes ? cellule.conditions : []}
                                              afficherTypeSiPasIcone={true}
                                              afficherEtatExtension={true}
                                              affichage_vrai={cellule.affichage_vrai}
                                              affichage_faux={cellule.affichage_faux}
                                              extension={cellule.extension}
                                              commentaire_a_valider={!!cellule.commentaire_a_valider}
                                              restartTitreAnim={open ? "oui" : "non"}
                                              afficherCondition={true}
                                              bouton_icone={"eye-slash"}
                                              bouton_onclick={() => display_cellule(cellule.id)}
                                />
                            )}
                        </>
                    );
                }
            })
            }
            {zone.immatriculation ? (
                <Carte_mesure titre={"Traceur GPS"} sonde={gps_sonde} texte={"Ouvrir"}
                              handleClick={() => alert("Ouvrir Traccar")}/>
            ) : ""}
            {!zone.immatriculation && zone.cellules?.length === 0 && (
                <div className={"inline-tag"}>Aucune cellule n'est configurée dans cette zone.</div>
            )}
        </>
    )

}
