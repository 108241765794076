import React, {useEffect, useState} from "react";

import './Outils_admin.scss'
import {FileButton, NativeSelect, NumberInput, Tabs, TextInput} from "@mantine/core";
import {Produit} from "../../../../types/produit";
import ajax from "../../../../services/AxiosInterceptor";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeRobot} from "../../../../components/icone/solid_react/robot";
import {IconeHandPaper} from "../../../../components/icone/solid_react/hand-paper";
import {afficherDate} from "../../../../services/GestionDates";
import {IconeFilmCanister} from "../../../../components/icone/solid_react/film-canister";
import {IconeFileCheck} from "../../../../components/icone/solid_react/file-check";
import {IconeFileImport} from "../../../../components/icone/solid_react/file-import";
import {IconeFilePdf} from "../../../../components/icone/solid_react/file-pdf";
import {IconeTriangleExclamation} from "../../../../components/icone/solid_react/triangle-exclamation";

export function Ajouter_un_capteur() {


    const [uuid, setUuid] = useState("");
    const [ref, setRef] = useState("");
    const [nom, setNom] = useState("");
    const [categorie_abonnement, setCategorie_abonnement] = useState("Evolution loué");
    const [numero_serie, setNumero_serie] = useState("");
    const [produit_id, setProduit_id] = useState<number | undefined>(undefined);

    const [nbe_capteur_a_creer, setNbe_capteur_a_creer] = useState<number>(1);

    const [loading_btn, setLoading_btn] = useState(false);
    const [loading_btn_compteur, setLoading_btn_compteur] = useState(0);
    const [loading_btn_compteur_echecs, setLoading_btn_compteur_echecs] = useState(0);

    const [produits, setProduits] = useState<Produit[] | null>(null);
    const [cli_output, setCli_output] = useState<string[]|null>(null);

    useEffect(() => {
        ajax.get("/bureau/admin/produits").then(response => {
            let _produits:Produit[] = response.data.produits;
            setProduits(_produits)
            _produits.map(p => {
                if (produit_id === undefined && !p.ref.includes("blu")) {
                    setProduit_id(p.id)
                }
            })
        }).catch(error => {
        });
    }, [])

    function handleAjouterCapteur(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (produit_id === undefined) {
            alert("Le produit n'est pas indiqué.")
            return;
        }
        setLoading_btn(true)
        ajax.post("/capteurs", {
            "nom": nom,
            "uuid": uuid,
            "ref_capteur": ref,
            "produit_id": produit_id,
            "numero_serie": numero_serie,
            "categorie_abonnement": categorie_abonnement,
        }).then(response => {
            setCli_output(response.data.detail)
            setLoading_btn(false)
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'l\'enregistreur à été créer.',
                icon: <IconeCheck/>,
            });
        }).catch(error => {
            setLoading_btn(false)
        });
    }

    const [fichier, setFichier] = useState<File|null>(null);
    const [offset_etiquettes, setOffset_etiquettes] = useState(0);
    function handle_generer_etiquettes() {
        const formData = new FormData();
        if (!fichier) {
            alert("Merci d'ajouter le fichier.");
            return
        }
        setLoading_btn(true);
        formData.append("offset", offset_etiquettes + "");
        if (fichier) formData.append("fichier", fichier);
        ajax.post("/be/gen_etiquettes", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(response => {
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'Le fichier est disponible.',
                icon: <IconeCheck/>,
            });
            setLoading_btn(false);
        }).catch(error => {
            setLoading_btn(false);
        })
    }

    async function handleAjouterCapteurAuto() {
        if (!window.confirm("Valider la création de " + nbe_capteur_a_creer + " enregistreurs ?")) return;

        let fichier_config:{ref:string, uuid:string, mqtt:string, used:null}[] = [];

        setLoading_btn_compteur_echecs(0)
        setLoading_btn(true)
        let nbe_erreurs = 0;

        for (let i = 0; i < nbe_capteur_a_creer; i++) {
            let generated_uuid = Array.from({ length: 16 })
                .map(() => Math.floor(Math.random() * 16).toString(16).toUpperCase())
                .join('');
            let generated_ref = Array.from({ length: 5 })
                .map(() => Math.random().toString(36).charAt(2))
                .join('').toUpperCase();
            // alert(generated_ref);
            // return;
            await ajax.post("/capteurs", {
                "nom": generated_ref,
                "uuid": generated_ref,
                "ref_capteur": generated_uuid,
                "produit_id": produit_id,
                "numero_serie": numero_serie,
                "categorie_abonnement": categorie_abonnement,
            }).then(response => {
                setCli_output(response.data.detail)
                fichier_config.push({
                    ref: generated_ref,
                    uuid: generated_uuid,
                    mqtt: response.data.pass,
                    used: null
                })
                setLoading_btn_compteur(i+1)
            }).catch(error => {
                nbe_erreurs++;
                setLoading_btn_compteur_echecs(nbe_erreurs)
            });
            // let aaa = () => new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //         let response = {data: {pass: "password"}};
            //         fichier_config.push({
            //             ref: generated_ref,
            //             uuid: generated_uuid,
            //             mqtt: response.data.pass,
            //             used: null
            //         })
            //         setLoading_btn_compteur(i+1)
            //         resolve(1);
            //     }, 250)
            // })
            // await aaa();

        }

        const blob = new Blob([JSON.stringify(fichier_config, null, 2)], { type: "application/json;charset=utf-8" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "bd.json";
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        notifications.show({
            withCloseButton: true,
            autoClose: env_config.notifications_duree_affichage,
            color: loading_btn_compteur_echecs > 0 ? 'orange' : 'green',
            title: loading_btn_compteur_echecs > 0 ? 'Terminé avec erreurs' : "Terminé!",
            message: 'Les enregistreurs ont étés créés',
            icon: <IconeCheck/>,
        });

        setLoading_btn(false)

    }

    return (

        <>

            <Tabs defaultValue="auto">
                <Tabs.List>
                    <Tabs.Tab value="auto" leftSection={<IconeRobot />}>
                        Automatique
                    </Tabs.Tab>
                    <Tabs.Tab value="manuel" leftSection={<IconeHandPaper />}>
                        Manuel
                    </Tabs.Tab>
                    <Tabs.Tab value="etiquettes" leftSection={<IconeFilmCanister />}>
                        Etiquettes
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="auto">
                    <div className={"fullwidth"}>
                        <p>Ce formulaire permet d'ajouter un ou plusieurs nouveaux appareils au système et vous donnera le fichier de configuration à utiliser pour programmer les enregistreurs neufs. </p>
                        {/*<p>Attention : les infos qui seront affichés ici comme les login MQTT ne seront pas sauvegardés en clair, donnez les à l'appareil en suivant sinon ils seront perdu.</p>*/}

                        <NativeSelect
                            data={produits ? produits.map(p => {
                                return {value: p.id + "", label: p.nom}
                            }) : []}
                            value={produit_id}
                            onChange={(event) => {
                                setProduit_id(parseInt(event.currentTarget.value))

                            }}
                            label="Type de produit"
                            description="Va définir quels sont les extensions et les paramètres de l'enregistreur, attention ce n'est pas modifiable par la suite."
                            withAsterisk
                            defaultValue={produits && produits[0] ? produits[0].id : ""}
                        />

                        <TextInput
                            label={"Numéro de lot / usine"}
                            description="Numéro de série composé du numéro de lot et numéro unique donné par l'usine"
                            value={numero_serie}
                            onChange={e => setNumero_serie(e.target.value)}
                        />

                        <TextInput
                            label={"Catégorie d'abonnement"}
                            description="Catégorie, acheté ou loué, pour les abonnements de l'appareil."
                            value={categorie_abonnement}
                            onChange={e => setCategorie_abonnement(e.target.value)}
                        />

                        <NumberInput
                            label={"Nombre d'enregistreur à créer "}
                            value={nbe_capteur_a_creer}
                            onChange={e => setNbe_capteur_a_creer(Number(e))}
                        />

                        <Loading_button is_loading={loading_btn} onClick={() => handleAjouterCapteurAuto()} className={"big"}>
                            Créer {nbe_capteur_a_creer} enregistreurs
                        </Loading_button>

                        {loading_btn && <p>{loading_btn_compteur}/{nbe_capteur_a_creer}</p>}
                        {loading_btn_compteur_echecs > 0 && (
                            <>
                                <strong className={"en-ligne"} style={{color: "red"}}>
                                    <IconeTriangleExclamation/> {loading_btn_compteur_echecs} en échec</strong>
                                <p style={{color: "red"}}>
                                    Si l'échec est du à une réf dupliquée, alors il faut relancer la création pour juste le nombre d'échec (ici : {loading_btn_compteur_echecs})
                                </p>
                                <p style={{color: "red"}}>
                                    Il faudra ensuite combiner manuellement les deux fichiers db.json pour en faire un seul.
                                </p>

                            </>
                        )}

                        {cli_output && (
                            <code className={"fullwidth"}>
                            {cli_output.map(c => <p>{"> "}{c}</p>)}
                            </code>

                        )}

                    </div>
                </Tabs.Panel>

                <Tabs.Panel value="manuel">
                    <form className={'form_admin'} onSubmit={event => handleAjouterCapteur(event)}>
                        <TextInput
                            label={"UUID (Réf Freshtime)"}
                            description="Référence unique de l'enregistreur, celle donné aux clients."
                            value={uuid}
                            onChange={e => {
                                setUuid(e.target.value)
                                if (nom === "" || nom === uuid) setNom(e.target.value)
                            }}
                            required
                        />
                        <TextInput
                            label={"Référence capteur/lora"}
                            description="Référence unique de l'enregistreur utilisé par l'appariel pour s'identifier en Lora"
                            value={ref}
                            onChange={e => setRef(e.target.value)}
                            required
                        />
                        <TextInput
                            label={"Nom"}
                            description="Nom pour affichage Admin, sera changé par le client quand il le mettra dans son organisation"
                            value={nom}
                            onChange={e => setNom(e.target.value)}
                            required
                        />

                        <TextInput
                            label={"Numéro de série"}
                            description="Numéro de série composé du numéro de lot et numéro unique donné par l'usine"
                            value={numero_serie}
                            onChange={e => setNumero_serie(e.target.value)}
                        />

                        <TextInput
                            label={"Catégorie d'abonnement"}
                            description="Catégorie, acheté ou loué, pour les abonnements de l'appareil."
                            value={categorie_abonnement}
                            onChange={e => setCategorie_abonnement(e.target.value)}
                        />
                        <NativeSelect
                            data={produits ? produits.map(p => {
                                return {value: p.id + "", label: p.nom}
                            }) : []}
                            value={produit_id}
                            onChange={(event) => {
                                setProduit_id(parseInt(event.currentTarget.value))

                            }}
                            label="Type de produit"
                            description="Va définir quels sont les extensions et les paramètres de l'enregistreur, attention ce n'est pas modifiable par la suite."
                            withAsterisk
                            defaultValue={produits && produits[0] ? produits[0].id : ""}
                        />

                        <p className={"inline-tag"}>Note : La référence Freshtime n'est pas utilisé pour l'instant, lors de l'ajout
                            d'un hub dans une organisation il faut utiliser l'UUID.</p>
                        {produits?.filter(p => p.id === produit_id)[0].ref.includes("blu") && (
                            <p className={"notice green"}>Pour ajouter un enregistreur blulog au système il est plus pertinent d'utiliser le catalogue Blulog
                                que d'ajouter un enregistreur par ce formulaire, sauf dans le cas d'un remplacement d'un enregistreur par un autre.</p>
                        )}

                        <div className={"fullwidth"}>
                            <Loading_button is_loading={loading_btn} onClick={() => {}} className={"big"}>Créer l'enregistreur</Loading_button>
                        </div>

                        {cli_output && (
                            <code className={"fullwidth"}>
                                {cli_output.map(c => <p>{"> "}{c}</p>)}
                            </code>

                        )}

                    </form>
                </Tabs.Panel>

                <Tabs.Panel value="etiquettes">
                    <div>
                        <p>Procédure : </p>
                        <p>1. Charger le fichier de configuration, indiquer l'offset et valider la génération.</p>
                        <p>2. Une fois la génération faite, cliquez sur le lien en bas pour télécharger le PDF.</p>
                    </div>


                        <NumberInput
                            label={"Nombre d'emplacement d'étiquette à ignorer sur la feuille"}
                            value={offset_etiquettes}
                            onChange={e => setOffset_etiquettes(Number(e))}
                        />

                        <FileButton onChange={setFichier}>
                            {(props) => <button style={{margin: "unset"}} className={"" + (fichier ? "vert" : "sec")} {...props}>
                                {fichier ? (
                                    <><IconeFileCheck/> {fichier.name}</>
                                ) : (<><IconeFileImport/> Upload le fichier de configuration (db.json)</>)}
                            </button>}
                        </FileButton>

                        <div className={"fullwidth"}>
                            <Loading_button is_loading={loading_btn} disabled={!fichier} onClick={handle_generer_etiquettes} className={"big"}>Générer le PDF d'étiquettes</Loading_button>
                        </div>

                        <a className={"en-ligne"} href="https://api.freshtime.fr/app/dl_fichier/etiquettes"><IconeFilePdf/> Télécharger le
                            fichier d'étiquette</a>

                </Tabs.Panel>
            </Tabs>


            {/*<div style={{width:"100%", height: "0.5px", backgroundColor: "#111111AA", marginTop: "1rem"}}></div>*/}


        </>


    );
}
