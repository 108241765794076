import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/store";
import {DateTZ} from "../services/GestionDates";


export function Force_refresh(
    {receiveForceRefresh}
    :{
        receiveForceRefresh?:() => void,
    })
{

    //const dispatch = useDispatch();

    const refreshTimestamp = useSelector<RootState, number>(state => state.interface.force_refresh)

    useEffect(() => {
        if (refreshTimestamp && refreshTimestamp === Math.round(new Date().getTime()/1000)) {
            if (receiveForceRefresh) receiveForceRefresh();
        }
    }, [refreshTimestamp]);

    return <></>;
}