export type permission = {
    slug: string,
    nom: string
    descr: string
    categorie: string
}

export const All_permissions:permission[] = [
    {
        slug: "Consulter_donnees",
        nom: "Consulter donnees",
        descr: "Permission essentielle, permet de consulter les mesures de l'organisation. Si la permission n'est pas accordé, l'utilisateur reste dans l'organisation mais n'y a pas accès.",
        categorie: "Acces"
    },
    {
        slug: "Administrer_anomalies",
        nom: "Gérer les anomalies",
        descr: "Permet de configurer les anomalies des cellules.",
        categorie: "Acces"
    },
    {
        slug: "Administrer_cellule",
        nom: "Administrer les cellules",
        descr: "Permet de créer et modifier les cellules et de configurer leur statut.",
        categorie: "Acces"
    },
    {
        slug: "Administrer_cycle",
        nom: "Administrer les cycles",
        descr: "Permet de démarrer et arrêter les cycles. (pour les appareils compatibles)",
        categorie: "Acces"
    },
    {
        slug: "Administrer_commentaire",
        nom: "Administrer les commentaires",
        descr: "Permet de créer et modifier les commentaires.",
        categorie: "Acces"
    },
    {
        slug: "Administrer_appareils",
        nom: "Administrer les appareils",
        descr: "Permet d'ajouter et de modifier les appareils de l'organisation",
        categorie: "Acces"
    },
    {
        slug: "Administrer_organisation",
        nom: "Administrer l'organisation",
        descr: "Gérer l'organisation, ses utilisateurs et leur permissions, les moyens de paiement, ...",
        categorie: "Acces"
    },
    {
        slug: "Gestion_alertes_perso",
        nom: "Gérer ses propres alertes",
        descr: "Permet à l'utilisateur de pouvoir configurer les alertes pour son compte",
        categorie: "Notifications"
    },
    {
        slug: "Gestion_alertes_toutes",
        nom: "Administrer toutes les alertes",
        descr: "Permet à l'utilisateur de pouvoir configurer les alertes de tous les utilisateurs de l'organisation",
        categorie: "Notifications"
    },
    {
        slug: "recevoir_email_infos_paiement",
        nom: "Recevoir les emails concernant les paiements",
        descr: "Si activé, l'utilisateur recevra les email concernant les paiements : délais, factures et informations de gestion",
        categorie: "Notifications"
    },
    {
        slug: "recevoir_email_analyses",
        nom: "Recevoir les emails d'analyses intelligentes",
        descr: "Si activé, l'utilisateur recevra les email déclenchés par les analyses intelligentes, quand celles-ci sont activé sur une ou plusieurs cellules de l'organisation.",
        categorie: "Notifications"
    },
    {
        slug: "recevoir_push_cycles",
        nom: "Recevoir les PUSH des alertes concernant les cycles",
        descr: "Si activé, l'utilisateur recevra les notification PUSH déclenchés par les cycles. Assurez vous que l'utilisateur à activer les PUSH sur son appareil.",
        categorie: "Cycle"
    },
    {
        slug: "recevoir_email_rapport_hebdo",
        nom: "Recevoir un rapport hebdomadaire des cellules en PDF",
        descr: "Si activé, l'utilisateur recevra les rapports PDF de toutes les cellules (hors contacts-secs) de l'organisation, une fois par semaine",
        categorie: "Rapport"
    },
    {
        slug: "recevoir_email_rapport_mensuel",
        nom: "Recevoir un rapport mensuel des cellules en PDF",
        descr: "Si activé, l'utilisateur recevra les rapports PDF des cellules (hors contacts-secs) de l'organisation, une fois par mois",
        categorie: "Rapport"
    },
]