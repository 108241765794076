import React, {useEffect, useState} from "react";
import Joyride, {ACTIONS, CallBackProps, EVENTS, STATUS, Step} from "react-joyride";
import {Organisation} from "../../types/organisation";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {User} from "../../types/user";
import {pasApasData} from "../../store/pasApasSlice";
import {Pas_a_pas_services} from "./pas_a_pas_services";

export function Cycles_tuto({}:{}) {

	const [tourIntro, setTourIntro] = useState<any>([]);
	const [tourStepIndex, setTourStepIndex] = useState<number>(0);
	const [tourRun, setTourRun] = useState<boolean>(false);

	const pasApas = useSelector<RootState, pasApasData>(state => state.pasApas)

	const [was_started, setWas_started] = useState(false);

	useEffect(() => {


		let run_etat:"keep"|"true"|"false"|"delay"|"delay+1" = "keep";
		let index = tourStepIndex;

		let tour:any = [
			{
				target: 'body',
				content:
					<div className={"flex-col"} style={{gap: "0.5rem"}}>
						<strong>Bienvenue sur la page Cycle. </strong>
						<p>Cette page vous permet de voir et démarrer les cycles de vos cellules de refroidissements.</p>
						<p>Nous allons créer un cycle pour vous montrer les fonctionnalités</p>
					</div>,
				placement: 'center',
				spotlightClicks: false,
			},
			{
				target: "#btn_creer_cycle",
				content:
					<>
						<p>Cliquez ici pour créer un cycle</p>
					</>,
				placement: 'bottom',
				spotlightClicks: true,
				hideFooter: true,
				disableScrolling: true,
			},
			{
				target: ".mantine-Modal-content",
				content:
					<div className={"flex-col"} style={{gap: "0.5rem"}}>
						<p>Voici les informations à renseigner pour créer un cycle</p>
					</div>,
				placement: 'left',
				spotlightClicks: true,
				hideBackButton: true,
				disableScrolling: true,
				// hideFooter: true,
			},
			// {
			// 	target: ".mantine-Modal-content",
			// 	content:
			// 		<div className={"flex-col"} style={{gap: "0.5rem"}}>
			// 			<p>Indiquez la cellule liée à la sonde que vous avez branché et l'intitulé du plat. </p>
			// 		</div>,
			// 	placement: 'left',
			// 	spotlightClicks: true,
			// 	// hideFooter: true,
			// },
			// {
			// 	target: ".mantine-Modal-content",
			// 	content:
			// 		<>
			// 			<p>Écrivez ici le plat concerné. Le système vous proposera automatiquement les plats renseignés précédemment pour vous faire gagner du temps.</p>
			// 		</>,
			// 	placement: 'left',
			// 	spotlightClicks: true,
			// 	// hideFooter: true,
			// },
			// {
			// 	target: ".mantine-Modal-content",
			// 	content:
			// 		<div className={"flex-col"} style={{gap: "0.5rem"}}>
			// 			<p>Indiquez ensuite le mode : </p>
			// 			<p>Mode positif : Le système vous informe quand le plat atteint 10°C.</p>
			// 			<p>Mode négatif : Le système vous informe quand le plat atteint -18°C.</p>
			// 		</div>,
			// 	placement: 'left',
			// 	spotlightClicks: true,
			// 	// hideFooter: true,
			// },
			{
				target: "#modale_creer_cycle_valider",
				content:
					<div  className={"flex-col"} style={{gap: "0.5rem"}}>
						<p>Cliquez ici pour valider et créer le cycle</p>
						<button onClick={() => setTourStepIndex(index)} >Retour</button>
					</div>,
				placement: 'top',
				spotlightClicks: true,
				hideFooter: true,
			},
			{
				target: "body",
				content:
					<div  className={"flex-col"} style={{gap: "0.5rem"}}>
						<p>Le tableau liste tous les cycles qui ont étés crées. En 1er sont ceux actuellement actifs.</p>
					</div>,
				placement: 'bottom',
				hideBackButton: true,
				// spotlightClicks: true,
				// hideFooter: true,
			},
			{
				target: "body",
				content:
					<div  className={"flex-col"} style={{gap: "0.5rem"}}>
						<p>Les colonnes -18°C seront remplies uniquement si vous avez coché le mode Négatif lors de la création du cycle.</p>
					</div>,
				placement: 'bottom',
				// spotlightClicks: true,
				// hideFooter: true,
			},
			{
				target: "body",
				content:
					<div  className={"flex-col"} style={{gap: "0.5rem"}}>
						<p>Vous pouvez manuellement arrêter un cycle, ou voir toutes ses mesures une fois qu'il est terminé.</p>
					</div>,
				placement: 'bottom',
				// spotlightClicks: true,
				// hideFooter: true,
			},
			{
				target: "#btn_push_cycle",
				content:
					<div  className={"flex-col"} style={{gap: "0.5rem"}}>
						<p>Pour activer ou désactiver les notifications PUSH des cycles, cliquez ici.</p>
					</div>,
				placement: 'bottom',
				// spotlightClicks: true,
				// hideFooter: true,
			},
		];

		if (pasApas.tour === null)
		{
			setTourRun(true)
			setWas_started(true)
		}




		setTourIntro(tour)

		setTourStepIndex(index)

		console.log("pasApas.etat : " + pasApas.etat)
		console.log("run_etat : " + run_etat)
		console.log("index : " + index)
		console.log("tour.length : " + tour.length)

		switch (run_etat) {
			// @ts-ignore
			case "delay":
				setTourRun(false)
				// setTourStepIndex(0)
				setTimeout(() => {
					setTourRun(true)
				}, 500)
				break;


			// @ts-ignore
			case "keep":
				// Rien faire
				break;

		}

	}, []);

	useEffect(() => {
		let index = tourStepIndex;

		let run_etat:"keep"|"true"|"false"|"delay"|"delay+1" = "keep";

		console.log("Etat : " + pasApas.etat);
		console.log("index : " + index);

		if (index === 1 && pasApas.etat === "modale_cycle_opened") {
			run_etat = "delay";
			index++;
		}
		if ([2,3].includes(index) && pasApas.etat === "modale_cycle_valide") {
			run_etat = "false";
			index = 4;
		}
		if ([4].includes(index) && pasApas.etat === "modale_cycle_close") {
			run_etat = "true";
		}

		setTourStepIndex(index)

		switch (run_etat) {
			case "delay":
				setTourRun(false)
				// setTourStepIndex(0)
				setTimeout(() => {
					setTourRun(true)
				}, 500)
				break;

			// @ts-ignore
			case "false":
				setTourRun(false)
				break;

			// @ts-ignore
			case "true":
				setTourRun(true)
				break;


			// @ts-ignore
			case "keep":
				// Rien faire
				break;

		}

	}, [tourStepIndex, pasApas.etat]);



	const handleJoyrideCallback = (data: CallBackProps) => {
		const { action, index, origin, status, type } = data;

		if (action === ACTIONS.CLOSE) {
			// do something
			setTourRun(false);
		}

		// @ts-ignore
		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour

			setTourStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));

		} else { // @ts-ignore
			if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
				// You need to set our running state to false, so we can restart if we click start again.
				setTourRun(false);
			}
		}

		// console.groupCollapsed(type);
		// console.log(data); //eslint-disable-line no-console
		// console.groupEnd();
	};

	return (
		<Joyride
			steps={tourIntro}
			stepIndex={tourStepIndex}
			callback={handleJoyrideCallback}
			run={tourRun}
			styles={{
				options: {
					// arrowColor: '#e3ffeb',
					// backgroundColor: '#e3ffeb',
					// overlayColor: 'rgba(79, 26, 0, 0.4)',
					primaryColor: 'var(--main-color)',
					zIndex: 100000
				},
				tooltipContainer: {
					lineHeight: 1.4,
					textAlign: 'left',
				},
			}}
			locale={{ back: 'Retour', close: 'Fermer', last: 'Fermer', next: 'Suivant', open: 'Ouvrir l\'aide', skip: 'Passer' }}
			continuous
			// showProgress
		/>
	)
}