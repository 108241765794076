import {Zone} from "./zone";
import {Capteur} from "./capteur";
import {Hub} from "./hub";
import {Cellule} from "./cellule";
import {Contact} from "./contact";
import {Regle_paiement} from "./regle_paiement";

export type Organisation = {
    id: number,
    nom: string,
    creation: string,
    coordonnees: { [key: string]: string },
    statut: {
        nom: string,
        deadline: string
    },
    prestataire: {
        id: number,
        nom: string
    },
    permissions?: string[],
    balance: number,
    credit_SMS: number,
    credit_Tel: number,
    orga_parent: null | number | Organisation,

    is_cycle: boolean,

    zones?: Zone[]
    is_fully_loaded?:boolean

    capteurs?:Capteur[]
    hubs?:Hub[]

    etat : string,
    desactivation_texte : string|null,
    prochainPrelevement: string|null
    contacts : Contact[],
    regles_paiements: Regle_paiement
    est_parent?:boolean //Uniquement sur la route de GET organisation, pour orga_admin

    "cBLabel": string|null,
    "cBPerime": string|null,
    "infosFacturation": Organisation_facturation|null,
}


export type Organisation_paiement = {

    total?: number
    totalTTC?: number
    avance?: number
    rattrapage?: number
    remboursement?: number
    promotion_montant?: number
    promotion_texte?: string|null
    promotion?: number
    SMS_a_crediter?: number
    Tel_a_crediter?: number

    statuts? : {
        cellule: Cellule,
        details: string,
        avance : number,
        remboursement : number,
        impaye : number,
        prix_final : number,
        promo : number,
        promo_texte : string,
        capteur_UUID: string
    }[]
    organisation: Organisation_paiement_orga_detail
    enfants: Orga_enfant[]

    paiements? : Paiementtype[]
}

export type Organisation_light = {
    id: number,
    nom: string
}

export type Organisation_paiement_orga_detail = {
    "id" : number
    "nom" : string
    "etat" : "New"|"Active"|"Desac_paiement"|"Desac_admin"//string
    "desactivation_texte" : string
    "prochainPrelevement" : string
    "balance" : number
    "credit_SMS" : number
    "credit_Tel" : number
    "cBLabel": string|null,
    "cBPerime": string|null,
    "infosFacturation": Organisation_facturation|null,
}

export type Paiementtype = {
    "id": number
    "organisation": Organisation_light
    "montant": number
    "facture_token"?: string
    "facture"?: {
        id: number
        nom: string
        token: string
    }
    "informations": Organisation_paiement|null
    "titre": string
    "enregistrement": string
    "transaction": Transactiontype|null
}

export type Paiementtype_full = {
    "id": number
    "organisation": Organisation
    "montant": number
    "facture_token"?: string
    "facture"?: {
        id: number
        nom: string
        token: string
    }
    "informations": Organisation_paiement|null
    "titre": string
    "enregistrement": string
    "transaction": Transactiontype|null
}

export type Transactiontype = {
    'id': number,
    'organisation': Organisation_light,
    'type': string,
    'date': string,
    'data': any|null,
    'codeStatut': string,
    'transactionReference': string,
    'transactionID': string,
    'montant': number,
}
export type Orga_enfant = {
    "id" : number
    "nom" : string
    "etat" : string
    "desactivation_texte" : string
    "prochainPrelevement" : string
    "balance" : number
    "credit_SMS" : number
    "credit_Tel" : number
}

export type Organisation_facturation = {
    estPro?:boolean,
    denominationSociale?:string,
    numero_tva?:string,
    numero_siret?:string,
    addresse?: {
        rue: string,
        ville: string,
        code_postal: string,
        pays: string,
    };
}

export const organisation_facturation_init:Organisation_facturation = {
    estPro:true,
    denominationSociale:"",
    numero_tva:"",
    numero_siret:"",
    addresse: {
        rue: "",
        ville: "",
        code_postal: "",
        pays: "France",
    }
}