import React, {forwardRef, useRef, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Avatar, Group, Modal, Select, Textarea, TextInput} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Organisation} from "../../../types/organisation";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeToggleOff} from "../../../components/icone/solid_react/toggle-off";
import {IconeToggleOn} from "../../../components/icone/solid_react/toggle-on";
import {IconeCalendar} from "../../../components/icone/solid_react/calendar";
import {DatePickerInput} from "@mantine/dates";
import {DateTZ} from "../../../services/GestionDates";

export function Modale_changer_date_prelevement({
                                                   organisation,
                                                   setOrganisation
                                               }: { organisation: Organisation, setOrganisation: (orga: Organisation) => void }) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);

    const [date, setDate] = useState<DateTZ|null>( organisation.prochainPrelevement ? new DateTZ(organisation.prochainPrelevement) : null );

    function handleEnregistrer() {


        ajax.put("/organisations/" + organisation.id + "", {
            "majprochainPrelevement": date?.toISOString() ?? null
        }).then(response => {
            let orga_maj = {...organisation};
            orga_maj.prochainPrelevement = date?.toISOString() ?? null;
            setOrganisation(orga_maj);
            handlers.close();
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
            handlers.close();
        })

        set_btn_loading(true);
    }

    return (
        <>
            <button className={"sec"} onClick={handlers.open}><IconeCalendar/>Date de prélèvement</button>


            <Modal opened={opened} onClose={handlers.close} title="Changer la date de prélèvement">


                    <DatePickerInput label={"Date de prélèvement : "}
                              value={date}
                                     locale="fr"
                                     valueFormat="DD/MM/YYYY"
                              onChange={event => {
                                  if (event) {
                                      let _e = new DateTZ(event.toISOString());
                                      _e.setHours(12, 0, 0);
                                      setDate(_e)
                                  } else {
                                      setDate(null)
                                  }
                              }}
                                clearable={true}
                    />


                    <Loading_button is_loading={btn_loading}
                                    onClick={handleEnregistrer} icone={"check"}>Enregistrer</Loading_button>


            </Modal>
        </>

    )
}