import {Navbar} from "../../../components/navbar/navbar";
import React, {useEffect, useState} from "react";

import './Cycles.scss'
import ajax from "../../../services/AxiosInterceptor";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import env_config from "../../../env_config";
import {User} from "../../../types/user";
import {setMobile_sidebar_icon} from "../../../store/interfaceSlice";
import {Bandeau_user} from "../../../components/sidebar/bandeau_user/bandeau_user";
import {Tableau_helper, typeDonnee} from "../../../components/tableau/Tableau_helper";
import {Cycle} from "../../../types/cycle";
import {IconeLoader} from "../../../components/icone/icone";
import {Combobox, Input, Tooltip, useCombobox} from "@mantine/core";
import {IconeMagnifyingGlass} from "../../../components/icone/solid_react/magnifying-glass";
import {texte_est_inclus} from "../../../services/GestionTextes";
import {IconeCircleInfo} from "../../../components/icone/solid_react/circle-info";
import {permissionIsGranted} from "../../../services/permissionsService";
import {Organisation} from "../../../types/organisation";
import {Loading_div} from "../../../components/loading_div/loading_div";
import {Cycle_creer} from "./cycle_creer";
import {Cellule} from "../../../types/cellule";
import {checkExistingToken} from "../../../services/firebase";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {afficherDateTime, afficherHeure, afficherHeureDepuisMinutes, DateTZ} from "../../../services/GestionDates";
import {IconeHourglass} from "../../../components/icone/solid_react/hourglass";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeXmark} from "../../../components/icone/solid_react/xmark";
import {IconeStop} from "../../../components/icone/solid_react/stop";
import {IconeEye} from "../../../components/icone/solid_react/eye";
import {Modale_graphique_export} from "../Mesures/Modales/modale_graphique_export";
import {setPasApasEtat} from "../../../store/pasApasSlice";
import {IconeArrowRotateLeft} from "../../../components/icone/solid_react/arrow-rotate-left";
import {Force_refresh} from "../../../components/force_refresh";
import {Cycles_tuto} from "../../../components/Tours/cycles_tuto";
import {IconeQuestion} from "../../../components/icone/solid_react/question";


export function Cycles() {

    const [cycles, setCycles] = useState<Cycle[] | null>(() => null);

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list.filter(o => o.is_cycle))

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const recherche_orga = queryParams.get('organisation');
    const recherche_cellule = queryParams.get('cellule');

    const [cellule_pour_modale, setCellule_pour_modale] = useState<{cellule:Cellule, debut:DateTZ, fin:DateTZ}|null>(null);

    const [recherche_orga_nom, setRecherche_orga_nom] = useState('');
    const [orga_selectionnee, setOrga_selectionnee] = useState<string>((organisations.filter(o => o.id + "" === recherche_orga)[0] && organisations.filter(o => o.id + "" === recherche_orga)[0].id + "") ?? organisations[0].id + "")
    const [cellules_en_cycles, setCellules_en_cycles] = useState<Cellule[]>([]);

    const orga_selectionnee_full = organisations.find(o => o.id + "" === orga_selectionnee);

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const [is_loading, setIs_loading] = useState(false);
    const navigate = useNavigate();

    const user = useSelector<RootState, User>(state => state.user)

    const [btn_simu_loading, setBtn_simu_loading] = useState(false);

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))

    useEffect(() => {
        queryLastInfos(true);
        const interval_clear = setInterval(() => {
            queryLastInfos(false);
        }, env_config.refresh_rate);


        return () => {
            clearInterval(interval_clear);
        }
    }, [orga_selectionnee])


    function queryLastInfos(loadind:boolean) {
        setIs_loading(loadind)
        if (!orga_selectionnee) return;
        console.log("orga select : " + orga_selectionnee)
        ajax.get("/organisations/" + orga_selectionnee + "/cycles").then(response => {
            setCycles(response.data.cycles.sort((a:Cycle, b:Cycle) => {
                if (a.mesureFin === null && b.mesureFin !== null) return -1
                if (a.mesureFin !== null && b.mesureFin === null) return 1
                if (new Date(a.active).getTime() === new Date(b.active).getTime()) return 0
                if (new Date(a.active).getTime() > new Date(b.active).getTime()) return -1
                return 1
            }));
            setCellules_en_cycles(response.data.cellules_en_cycles);
            setIs_loading(false);
        }).catch(e => {
            setIs_loading(false)
        })
    }

    useEffect(() => {
        queryLastInfos(true);
    }, [orga_selectionnee]);

    function handleVoir(cycle:Cycle)
    {
        // navigate("/cellule/" + cycle.cellule?.id + "?")

        ajax.get('/cellules/' + cycle.cellule?.id)
            .then(response => {
                let cellu: Cellule = response.data.cellule;
                let setCellu = () => {
                    setCellule_pour_modale(
                    {
                        cellule: cellu,
                        debut: new DateTZ(cycle.active),
                        fin: new DateTZ(cycle.heureMesureFin ?? cycle.heureMesureFrais ?? cycle.heureMesureRef ?? cycle.heureFirstMesure),
                    })
                }
                if (cellule_pour_modale){
                    setCellule_pour_modale(null)
                    setTimeout(() => setCellu, 500);
                } else { setCellu() }
            }).catch(error => {
            console.log(error);
        });




    }

    function handleStopCycle(cycle_id:number)
    {
        if (!window.confirm("Etes-vous sûr de vouloir arrêter ce cycle ?")) return;
        ajax.post("/cycles/" + cycle_id + "/stop").then(res => {
            queryLastInfos(true);
        }).catch(err => {})
    }


    const isNumeric = (value: any): boolean => !isNaN(parseFloat(value)) && isFinite(value as number);



    let typeDonneesCycles: typeDonnee[] = [
        {key: "plat", key_tab: "id", nom: "ID", noedit: true, edit_display_value: (value, full_row:Cycle) => full_row.id+""},
        {key: "plat", key_tab: "statut", nom: "Statut",
            edit_display_value: (texte, full_row:Cycle) =>
            {
                if (full_row.mesureFin)
                {
                    // Cycle fini
                    if (full_row.mesureFrais && Number(full_row.mesureFrais) <= 10)
                    {
                        return 'Refroidissement réussi'
                    }
                    return 'Refroidissement échoué'
                }
                else{
                    return "En cours"
                }
            },
            wrapper: (texte, full_row:Cycle) =>
                <Tooltip label={texte} >
                    <div>
                        {texte === "En cours" && <p style={{color: "var(--grey-color)", display:"flex", justifyContent: "center"}}><IconeHourglass/></p>}
                        {texte === "Refroidissement réussi" && <p style={{color: "var(--valid-color)", display:"flex", justifyContent: "center"}}><IconeCheck/></p>}
                            {texte === "Refroidissement échoué" && <p style={{color: "var(--error-color)", display:"flex", justifyContent: "center"}}><IconeXmark /></p> }
                    </div>

                </Tooltip>
        },
        {key: "cellule.nom", nom: "Cellule", wrapper: (texte, full_row:Cycle) => <Link to={"/cellule/" + full_row.cellule?.id} >{full_row.cellule?.nom}</Link>},
        {key: "plat", nom: "Plat"},
        {key: "active", nom: "Démarrage", type: "date"},
        {key: "firstMesure", nom: "1ère mesure reçue",
            edit_display_value: (texte, full_row:Cycle) =>
                !texte ? "" : full_row.firstMesure+"°C à "+afficherDateTime(full_row.heureFirstMesure)
        },
        {key: "mesureRef", nom: "63°C",
            edit_display_value: (texte, full_row:Cycle) =>
                !texte ? "" : full_row.mesureRef+"°C à "+afficherDateTime(full_row.heureMesureRef)
        },
        {key: "mesureFrais", nom: "10°C",
            edit_display_value: (texte, full_row:Cycle) =>
                !texte ? "" : full_row.mesureFrais+"°C à "+afficherDateTime(full_row.heureMesureFrais)
        },
        {key: "mesureCongel", nom: "-18°C",
            edit_display_value: (texte, full_row:Cycle) =>
                !texte ? "" : full_row.mesureCongel+"°C à "+afficherDateTime(full_row.heureMesureCongel)
        },
        {key: "mesureFin", nom: "Fin du cycle",
            edit_display_value: (texte, full_row:Cycle) =>
                !texte ? "" : full_row.mesureFin + (isNumeric(full_row.mesureFin) ? "°C" : "") + " à "+afficherDateTime(full_row.heureMesureFin)
        },
        {key: "mesureFin", key_tab: "duree", nom: "Durée 10°C",
            edit_display_value: (texte, full_row:Cycle) =>
            {
                if (!full_row.heureMesureFrais || !full_row.heureMesureRef) return ""
                let duree = Math.round((new DateTZ(full_row.heureMesureFrais).getTime() - new DateTZ(full_row.heureMesureRef).getTime()) / 60000)
                return afficherHeureDepuisMinutes(duree)
            }
        },
        {key: "mesureFin", key_tab: "duree", nom: "Durée -18°C",
            edit_display_value: (texte, full_row:Cycle) =>
            {
                if (!full_row.heureMesureCongel || !full_row.heureMesureRef) return ""
                let duree = Math.round((new DateTZ(full_row.heureMesureCongel).getTime() - new DateTZ(full_row.heureMesureRef).getTime()) / 60000)
                return afficherHeureDepuisMinutes(duree)
            }
        },
        {key: "modeCongel", nom: "Mode congélation", type: "boolean"},
        {key: "plat", key_tab: "voir", nom: "Voir",
            cacherDansTableSi: (row:Cycle) => row.mesureFrais === null && row.mesureCongel === null && row.mesureFin === null,
            wrapper: (texte, full_row:Cycle) =>
                <button onClick={() => handleVoir(full_row)}><IconeEye /></button>
        },
        // {key: "informations", wrapper: (texte:string) => (
        //         <Tooltip label={texte} onClick={() => alert(texte)}>
        //             <p className={"inline-tag"}><IconeEye /></p>
        //         </Tooltip>),
        //     spanClassname: "no-wrap flex_align_center",
        //     desc: "Description affichée au client",
        //     type: "textarea"
        // },
        // {action_editer: true},
    ]

    if (permissionIsGranted(orga_selectionnee_full?.id, "Administrer_cycle"))
    {
        typeDonneesCycles.push(
            {key: "plat", key_tab: "stop", nom: "Stop",
                cacherDansTableSi: (row:Cycle) => row.mesureFin !== null,
                wrapper: (texte, full_row:Cycle) =>
                    <button onClick={() => handleStopCycle(full_row.id)}><IconeStop /></button>
            }
        );
    }


    const [ouvrir_tuto, setOuvrir_tuto] = useState(false);
    useEffect(() => {
        if (cycles?.length === 0) setOuvrir_tuto(true);
    }, [cycles]);


    const [permission_push_active, setPermission_push_active] = useState(orga_selectionnee_full?.permissions?.includes("recevoir_push_cycles") ?? false);
    const [bnt_recevoir_push_loading, setBnt_recevoir_push_loading] = useState(false);
    function activer_push()
    {
        if (!orga_selectionnee_full) return;
        setBnt_recevoir_push_loading(true);
        if (permission_push_active)
        {
            ajax.post("/organisations/"+orga_selectionnee_full.id+"/desactiver_push_cycles").then(res => {
                setPermission_push_active(false);
                setBnt_recevoir_push_loading(false);
            }).catch(err => {
                setBnt_recevoir_push_loading(false);
            })
        } else {
            ajax.post("/organisations/"+orga_selectionnee_full.id+"/activer_push_cycles").then(res => {
                setPermission_push_active(true);
                setBnt_recevoir_push_loading(false);
            }).catch(err => {
                setBnt_recevoir_push_loading(false);
            })
        }
    }

    useEffect(() => {
        setPermission_push_active(orga_selectionnee_full?.permissions?.includes("recevoir_push_cycles") ?? false)
    }, [orga_selectionnee]);


    useEffect(() => {
        if (cycles) dispatch(setPasApasEtat("page_cycle_loaded"))
    }, [cycles]);

    return (
        <div className={"app"}>
            <Navbar/>

            <div className={"content content-fullwidth en-tete-pages"}>


                <div className={"en-ligne en-tete-page"}>
                    <div className={"titre"}>
                        <h1>Vos cycles</h1>
                        <p>
                            Cette page vous permet de voir et créer les cycles de votre organisation.
                        </p>
                    </div>

                    <div className={"mini-sidebar"}>
                        <Bandeau_user/>
                    </div>


                </div>


                {organisations && (
                    <div style={{margin: "1rem"}}>
                        <div className={"recherche"}>
                            <Combobox
                                store={combobox}
                                onOptionSubmit={(val) => {
                                    setOrga_selectionnee(val ?? "")
                                    combobox.closeDropdown();
                                }}
                            >
                                <Combobox.Target>
                                    <Input
                                        component="button"
                                        type="button"
                                        pointer
                                        rightSection={<Combobox.Chevron/>}
                                        rightSectionPointerEvents="none"
                                        leftSection={<IconeMagnifyingGlass/>}
                                        className={"paiement_input_recherche"}
                                        onClick={() => combobox.toggleDropdown()}
                                    >
                                        {organisations.find(o => o.id + "" === orga_selectionnee)?.nom
                                            || <Input.Placeholder>Recherche d'une organisation...</Input.Placeholder>}
                                    </Input>
                                </Combobox.Target>

                                <Combobox.Dropdown>
                                    <Combobox.Search
                                        value={recherche_orga_nom}
                                        onChange={(event) => setRecherche_orga_nom(event.currentTarget.value)}
                                        placeholder="Recherche d'une organisation..."
                                    />
                                    <Combobox.Options>
                                        {organisations.filter((o) => texte_est_inclus(o.nom, recherche_orga_nom)).length === 0 &&
                                            <Combobox.Empty>Aucune organisation trouvée.</Combobox.Empty>}
                                        {organisations.filter((o) => texte_est_inclus(o.nom, recherche_orga_nom)).map((o) => (
                                            <Combobox.Option value={o.id + ""} key={o.id}
                                                             className={"en-ligne"}>
                                                {o.nom}
                                            </Combobox.Option>
                                        ))}</Combobox.Options>
                                </Combobox.Dropdown>
                            </Combobox>
                        </div>



                    </div>
                )}

                {orga_selectionnee_full && !is_loading && (
                    <Tableau_helper
                        typeDonnee={typeDonneesCycles}
                        donnees={cycles}
                        // handleEnregistrer={handleEnregistrer}
                        // formatNew={{
                        //     id : -1,
                        //     nom : "",
                        //     informations : "",
                        // }}
                        boutons={
                            <>
                                {permissionIsGranted(undefined, "ADMIN") && (
                                    <Loading_button is_loading={btn_simu_loading} onClick={() => {
                                        setBtn_simu_loading(true)
                                        ajax.get("/be/start_simu_cycle").then(res => {setBtn_simu_loading(false)}).catch(res => {setBtn_simu_loading(false)})
                                    }}>Lancer simulation</Loading_button>
                                )}

                                <div id={"btn_push_cycle"}>
                                    {permission_push_active ?
                                        <Loading_button is_loading={bnt_recevoir_push_loading} onClick={activer_push}>Ne plus recevoir les alertes des Cycles via PUSH</Loading_button>
                                        :
                                        <>
                                            {user.notif_push ?
                                                <Loading_button is_loading={bnt_recevoir_push_loading} onClick={activer_push}>Recevoir les alertes des Cycles via PUSH sur cet appareil</Loading_button>
                                                :
                                                <Tooltip
                                                    label={"Vous devez d'abord activer les notifications PUSH depuis la page Mon Compte"}>
                                                    <button disabled={true}>Recevoir les alertes des Cycles via PUSH sur cet appareil
                                                    </button>
                                                </Tooltip>
                                            }

                                        </>

                                    }
                                </div>


                                {permissionIsGranted(orga_selectionnee_full.id, "Administrer_cycle") && (
                                    <>
                                        {cellules_en_cycles.length > 0 ?
                                            <Cycle_creer organisation={orga_selectionnee_full} cellules_en_cycles={cellules_en_cycles} cellules_en_cycles_active={cycles?.filter(c => c.mesureFin === null && c.cellule !== null).map(c => c.cellule) ?? []} plats={cycles?.map(c => c.plat) ?? []} refresh={() => queryLastInfos(true)} />
                                            : "Vous n'avez aucune cellule sur vos appareils en cycle."}
                                    </>
                                )}

                                <button onClick={() => queryLastInfos(true)}><IconeArrowRotateLeft /> Actualiser</button>

                                <button onClick={() => {
                                    setOuvrir_tuto(false);
                                    setTimeout(() => setOuvrir_tuto(true), 500)
                                }}><IconeQuestion /></button>

                            </>
                        }
                        set_columnFilters={recherche_cellule ? [{id: "cellule.nom", value: recherche_cellule}] : undefined}
                    />
                )}

                {is_loading && <Loading_div />}

                {cellule_pour_modale && (
                    <Modale_graphique_export cellule={cellule_pour_modale.cellule} setCellule={() => {
                        setCellule_pour_modale(null)
                    }} modeCache={true} debut={cellule_pour_modale.debut} fin={cellule_pour_modale.fin} />
                )}


                <Force_refresh receiveForceRefresh={() => queryLastInfos(false)} />


            </div>

            {ouvrir_tuto && (
                <Cycles_tuto />
            )}



        </div>

    );
}
