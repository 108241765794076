import React, {useEffect, useRef, useState} from 'react';
import '../carte_mesure.scss'
import {Capteur} from "../../../types/capteur";
import {useNavigate} from "react-router-dom";
import {Tooltip} from "@mantine/core";
import {Icone_batterie} from "../../icone/icone_batterie/Icone_batterie";
import {Icone_signal} from "../../icone/icone_signal/Icone_signal";
import {DateTZ, getCurrentDate} from "../../../services/GestionDates";
import env_config from "../../../env_config";
import {IconeMicrochip} from "../../icone/solid_react/microchip";

export function Carte_capteur(
    {
        capteur, onClick
    }: {
        capteur: Capteur, onClick?: () => void
    }) {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const titreRef = useRef<HTMLParagraphElement>(null);

    const navigate = useNavigate();

    useEffect(() => {
        const titreElement = titreRef.current;
        if (titreElement) {
            setIsOverflowing(titreElement.scrollWidth > titreElement.clientWidth);
        }
    }, []);

    let niveau_batterie = capteur.niveau_batterie;
    let rssi = capteur.rssi;
    let snr = capteur.snr;

    let indicateurs = null
    if (niveau_batterie || rssi) {
        indicateurs = (
            <div className={"conditions"} style={{justifyContent: "space-between"}}>
                {}
                {niveau_batterie && (
                    <div className={"item"}>
                        <Tooltip label={niveau_batterie + "%"} position={"right"}>
                            <div>
                                <Icone_batterie niveau_batterie={niveau_batterie}/>
                            </div>
                        </Tooltip>
                    </div>
                )}
                {rssi && (

                    <div className={"item"}>
                        <Tooltip label={rssi + "dBm"} position={"left"}>
                            <div>
                                <Icone_signal rssi={rssi} snr={snr}/>
                            </div>
                        </Tooltip>
                    </div>
                )}
            </div>
        )
    }

    let couleur = "green";
    if (capteur.derniere_mesure && new DateTZ(Date.parse(capteur.derniere_mesure))  < new DateTZ(getCurrentDate().getTime() - env_config.duree_affichage_deconnexion_capteur)){
        couleur = "grey";
    }

    return (
        <div className={"carte_mesure " + couleur} id={"carte_capteur_" + capteur.uuid}
             onClick={onClick}>
            <div className={"titre"}>
                <IconeMicrochip />
                <div className={"titre_container"}>
                    <p className={"titre_texte" + (isOverflowing ? ' slide' : '')} ref={titreRef}>{capteur.nom}</p>
                </div>
            </div>
            <div className={"valeur en-ligne"}>
                {/*<p style={{fontSize: "2.5rem"}}><Icone nom={"microchip"}/></p>*/}

                <p className={"inline-tag"}>{capteur.produit.nom}
                    <br />
                    {capteur.uuid}
                </p>
            </div>


            {indicateurs}
        </div>
    );
}