import {Navbar} from "../../../components/navbar/navbar";
import {Bandeau_user} from "../../../components/sidebar/bandeau_user/bandeau_user";
import React, {useEffect, useRef, useState} from "react";
import './Orga_admin.scss'
import {Link, unstable_usePrompt, useLocation, useNavigate, useParams} from "react-router-dom";
import {Loading_div} from "../../../components/loading_div/loading_div";
import {IconeLoader} from "../../../components/icone/icone";
import ajax from "../../../services/AxiosInterceptor";
import {afficherDate, afficherDateTime, DateTZ} from "../../../services/GestionDates";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {notifications} from "@mantine/notifications";
import {Organisation, Organisation_paiement_orga_detail} from "../../../types/organisation";
import {Carte_mesure} from "../../../components/carte_mesure/carte_mesure";
import {gps_sonde} from "../../../types/sonde";
import {Zone} from "../../../types/zone";
import {Modale_ajouter_appareil} from "./modale_ajouter_appareil";
import {Carte_capteur} from "../../../components/carte_mesure/carte_appareil/carte_capteur";
import {Modale_utilisateurs} from "./modale_utilisateurs";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {User} from "../../../types/user";
import {All_permissions} from "../../../types/permissions";
import {Modale_desactiver_organisation} from "./modale_desactiver_organisation";
import {editOrganisation} from "../../../store/organisationSlice";
import env_config from "../../../env_config";
import Sidebar from "../../../components/sidebar/Sidebar";
import {setMobile_sidebar_icon} from "../../../store/interfaceSlice";
import {Carte_hub} from "../../../components/carte_mesure/carte_appareil/carte_hub";
import {Menu, Tooltip} from "@mantine/core";
import {permissionIsGranted} from "../../../services/permissionsService";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeCircleExclamation} from "../../../components/icone/solid_react/circle-exclamation";
import {IconePlus} from "../../../components/icone/solid_react/plus";
import {IconeCircleInfo} from "../../../components/icone/solid_react/circle-info";
import {IconeGear} from "../../../components/icone/solid_react/gear";
import {IconeBells} from "../../../components/icone/solid_react/bells";
import {IconeArrowUpLeftFromCircle} from "../../../components/icone/solid_react/arrow-up-left-from-circle";
import {IconeTrashCan} from "../../../components/icone/solid_react/trash-can";
import {IconeTrash} from "../../../components/icone/solid_react/trash";
import {IconeEuroSign} from "../../../components/icone/solid_react/euro-sign";
import Joyride, {ACTIONS, CallBackProps, EVENTS, STATUS} from "react-joyride";
import {Orgadmin_intro_tour} from "../../../components/Tours/Apres_inscription/Orgadmin_intro_tour";
import {Orgadmin_presenter_zones} from "../../../components/Tours/Orgadmin_presenter_zones";
import {pasApasData, setPasApasEtat} from "../../../store/pasApasSlice";
import {Modale_promo_nbe_capteurs} from "../../../components/modale_promo_nbe_capteurs/modale_promo_nbe_capteurs";
import {Modale_changer_date_prelevement} from "./modale_changer_date_prelevement";

export function Orga_admin() {

    let orgaId = useParams().orgaId;
    const [orga, setOrga] = useState<Organisation | null>(null);
    const [unsaveOrga, setUnsaveOrga] = useState<Organisation | null>(orga);
    const [btn_save_loading, setBtn_save_loading] = useState(false);
    const [btn_addzone_loading, setBtn_addzone_loading] = useState(false);
    const [btn_add_vehicule_loading, setBtn_add_vehicule_loading] = useState(false);
    const [btn_add_cellule_loading, setBtn_add_cellule_loading] = useState(false);

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("pen-to-square"))

    const user = useSelector<RootState, User>(state => state.user)

    const navigate = useNavigate();
    const scrollToRef = useRef<HTMLDivElement>(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pasApas = useSelector<RootState, pasApasData>(state => state.pasApas)

    const [nouveau_capteurs_a_surbriller, setNouveau_capteurs_a_surbriller] = useState<string[]>([]);

    const organisations_user_est_admin = useSelector<RootState, Organisation[]>(state =>
        state.organisation.list.filter(o => o.permissions?.includes("Administrer_organisation") && o.id !== orga?.id))
    unstable_usePrompt({
        "when": compareOrgaWithUnsave(),
        "message": "Des modifications n'ont pas été sauvegardé. Etes vous sûr de vouloir quitter la page?"
    });

    function compareOrgaWithUnsave()
    {
        return JSON.stringify(orga) !== JSON.stringify(unsaveOrga);
    }

    const [controlKeyHeld, setControlKeyHeld] = useState(false);
    function downHandler({key}:{key:string}) {
        if (key === 'Control') {
            setControlKeyHeld(true);
        }
    }

    function upHandler({key}:{key:string}) {
        if (key === "Control") {
            setControlKeyHeld(false);
        }
    }





    const [tourIntro, setTourIntro] = useState<any>([]);
    const [tourStepIndex, setTourStepIndex] = useState<number>(0);
    const [tourRun, setTourRun] = useState<boolean>(true);
    const handleJoyrideCallback = (data: CallBackProps) => {
        const { action, index, origin, status, type } = data;

        if (action === ACTIONS.CLOSE) {
            // do something
            setTourRun(false);
        }

        // @ts-ignore
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour

            setTourStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));

        } else { // @ts-ignore
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                // You need to set our running state to false, so we can restart if we click start again.
                setTourRun(false);
            }
        }

        console.groupCollapsed(type);
        console.log(data); //eslint-disable-line no-console
        console.groupEnd();
    };
    useEffect(() => {
        let tour:any = nouveau_capteurs_a_surbriller.map(capt => {
            return {
                target: '#carte_capteur_' + capt,
                content: "Cliquez sur votre nouvel appareil pour le configurer",
                spotlightClicks: true,
            }
        });

        setTourIntro(tour)
        if (nouveau_capteurs_a_surbriller.length > 0) setTourRun(true)
    }, [nouveau_capteurs_a_surbriller]);





    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, []);

    useEffect(() => {

        if (orga) setOrga(null)

            ajax.get('/organisations/' + orgaId)
                .then(response => {
                    let _orga: Organisation = response.data.organisation;
                    ajax.get('/organisations/' + orgaId + '/lastInfos')
                        .then(response => {
                            _orga.zones = response.data.zones
                            _orga.capteurs = response.data.capteurs
                            _orga.hubs = response.data.hubs
                            _orga.permissions = response.data.permissions
                            if (response.data.permissions.includes("ADMIN")) All_permissions.filter(p => p.categorie !== "Cycle").map(p => _orga.permissions?.push(p.slug));
                            setOrga(_orga);
                            setUnsaveOrga(_orga);

                        }).catch(error => {
                    });
                }).catch(error => {
            });
        },
        [orgaId]
    );
    useEffect(() => {
            //scroll to zone quand nouvelle zone ajoutée
            if (scrollToRef.current) {
                scrollToRef.current.scrollIntoView({
                    behavior: 'smooth', // Optional: Add smooth scrolling effect
                    block: 'center',     // Optional: Scroll to the top of the element
                });
            }

            if (orga?.orga_parent != unsaveOrga?.orga_parent)
            {
                handleSauvegarder()
            }

            if (orga) dispatch(setPasApasEtat("page_orga_loaded"))

        }, [orga]
    );

    if (!orga) {
        return (
            <div className={"app"}>
                <Navbar/>
                <div className={"content page_orga"}>
                    <p>&nbsp;</p>
                    <Loading_div customTexte={"Chargement Organisation " + orgaId + "..."}/>
                </div>

                <Sidebar>
                    <Bandeau_user/>
                    <Loading_div/>
                </Sidebar>
            </div>
        )
    }


    function handleSauvegarder() {
        if (!btn_save_loading) setBtn_save_loading(true);
        // console.log(orga)
        if (orga) {
            let parent_id = 0;
            if (typeof orga.orga_parent === "number") parent_id = orga.orga_parent;
            if (typeof orga.orga_parent === "object" && orga.orga_parent) parent_id = orga.orga_parent.id
            ajax.put("/organisations/" + orgaId, {
                "nom": orga.nom,
                "coordonnees": orga.coordonnees,
                "zones": orga.zones,
                "orga_parent": parent_id
            }).then(response => {
                setBtn_save_loading(false);
                notifications.show({
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'green',
                    title: "Enregistré!",
                    message: 'Vos données sont sauvegardés.',
                    icon: <IconeCheck/>,
                });

                dispatch(editOrganisation(orga))

                setUnsaveOrga({...orga});
            }).catch(error => {
                setBtn_save_loading(false);
            });
        }

    }

    function handleAddZone() {
        if (!btn_addzone_loading) setBtn_addzone_loading(true);
        ajax.post('/organisations/' + orgaId + "/zones", {
            "nom": "Nouvelle zone"
        })
            .then(response => {
                if (orga) {
                    let _orga = JSON.parse(JSON.stringify(orga));
                    if (!_orga.zones) _orga.zones = [];
                    _orga.zones.push(response.data.zone);

                    setOrga(_orga);
                    setBtn_addzone_loading(false);

                } else alert("Erreur, merci de rafraichir la page.");


            })
            .catch(error => {
                console.log(error);
            });

    }

    function handleAddCellule(zone_id: number) {
        if (!btn_add_cellule_loading) setBtn_add_cellule_loading(true);
        ajax.post('/zones/' + zone_id + "/cellules", {
            "nom": "Nouvelle cellule"
        })
            .then(response => {
                if (orga) {
                    setBtn_add_cellule_loading(false);
                    let _orga: Organisation = {...orga};
                    _orga.zones = orga.zones?.map(_zone => {
                        if (_zone.id === zone_id) {
                            let edit_zone = {..._zone};
                            if (!edit_zone.cellules) edit_zone.cellules = [];
                            edit_zone.cellules.push(response.data.cellule);
                            return edit_zone
                        }
                        return _zone
                    })

                    setOrga(_orga);
                    if (!compareOrgaWithUnsave()){
                        setUnsaveOrga(_orga)
                    }

                    navigate("/cellule/" + response.data.cellule.id + "?forcerconfig=true");

                } else alert("Erreur, merci de rafraichir la page.");




            })
            .catch(error => {
                console.log(error);
            });

    }

    function handleDeleteZone(zone: Zone) {
        if (zone.cellules && zone.cellules?.length > 0) {
            alert("Vous devez d'abord déplacer ou supprimer les cellules de cette zone pour pouvoir la supprimer.");
            return;
        }
        ajax.delete('/zones/' + zone.id)
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
        if (orga) {
            let _orga = {...orga};
            if (orga?.zones) _orga.zones = orga.zones.filter(_zone => _zone.id !== zone.id)

            setOrga(_orga);
        }

    }

    function handleDeleteOrganisation() {
        if (!window.confirm("Vous aller supprimer l'organisation. Cela est irréversible.") || !orga) {
            return;
        }
        ajax.delete('/organisations/' + orga.id)
            .then(response => {
                console.log(response)
                navigate("/");
            })
            .catch(error => {
                alert("La suppression à échouée. Assurez vous de vider l'organisation d'abord : supprimer les appareils et cellules. " +
                    "   Si une cellule à déjà des mesures, alors l'organisation ne peut pas être supprimée.")
                console.log(error);
            });
    }

    let nbe_cellules = 0;
    orga?.zones?.map(z => {
        nbe_cellules += z.cellules?.length ?? 0
    })

    return (

        <div className={"app"}>
            <Navbar/>
            <div className={"content page_orga"}>
                <div className={"en-tete fixed"}>
                    <h1 className={"hide_on_mobile"}>
                        {(orga.desactivation_texte !== null || orga.etat === "Desac_paiement") ? (
                            <span className={"en-ligne"} style={{color: "var(--error-color)"}}><IconeCircleExclamation /> Organisation désactivée</span>
                        ) : "Organisation"}</h1>
                    {permissionIsGranted(Number(orgaId), "Administrer_organisation") && (
                        <div className={"boutons"}>
                            {btn_add_cellule_loading ? (<><IconeLoader /> Chargement...</>) : ""}
                            <Loading_button id={"btn_ajouter_zone"} className={"sec"} onClick={handleAddZone} is_loading={btn_addzone_loading}
                                            icone={"grid-2-plus"}>Ajouter une zone</Loading_button>
                            {/*<Loading_button className={"sec"} onClick={() => alert("click add zone")}*/}
                            {/*                is_loading={btn_add_vehicule_loading}*/}
                            {/*                icone={"truck"}>Ajouter un véhicule</Loading_button>*/}
                            <Loading_button onClick={handleSauvegarder} is_loading={btn_save_loading}
                                            className={(!compareOrgaWithUnsave() ? "" : "red")}
                                            icone={"floppy-disk"}>Enregistrer</Loading_button>
                        </div>
                    )}

                </div>

                <div className={"zones scrollable"}>
                    <h1 className={"only_mobile"} style={{marginLeft: "0.25rem"}}>
                        {(orga.desactivation_texte !== null || orga.etat === "Desac_paiement") ? (
                            <span className={"en-ligne"} style={{color: "var(--error-color)"}}><IconeCircleExclamation /> Organisation désactivée</span>
                        ) : "Organisation"}</h1>


                    <input className={"nomCapteur"} type="text"
                           value={orga.nom}
                           onChange={event => {
                               const maj = {...orga};
                               maj.nom = event.target.value;
                               setOrga(maj);
                           }
                           }
                    />

                    {orga.etat === "Desac_paiement" && (
                        <p className={"notice"}>Votre organisation est désactivée temporairement suite à un défaut de paiement.
                            <br />Les mesures pour lequelles vous avez payés sont accessible mais les nouvelles mesures ne le seront pas.
                            <br />Vous trouverez plus d'infos sur la page "Paiement" dans le menu de gauche.</p>
                    )}
                    {orga.zones?.map(zone => {

                        if (zone.nom === "base.config.appareils") {
                            return (
                                <div className={"zone"} id={"zone-appareils"} key={zone.id}>
                                    {orga && orga.capteurs && orga?.capteurs?.length === 0 && (
                                        <p className={"notice green"}>
                                            Bienvenue sur la page de votre organisation.
                                            <br />
                                            Avant de pouvoir créer des cellules virtuelles pour stocker vos mesures, il faut renseigner un appareil :
                                        </p>
                                    )}
                                    <div className={"en-tete"}>
                                        <h4>Appareils de l'organisation</h4>
                                    </div>


                                    <div className={"mesures"}>
                                        {orga?.capteurs?.map(capteur => {
                                            return (
                                                <Carte_capteur capteur={capteur} key={capteur.uuid} onClick={() => navigate("/capteur/" + capteur.uuid)}/>
                                            );
                                        })}
                                        {orga?.hubs?.map(hub => {
                                            return (
                                                <Carte_hub hub={hub} key={hub.uuid}/>
                                            );
                                        })}
                                        {zone.immatriculation ? (
                                            <Carte_mesure titre={"Traceur GPS"} sonde={gps_sonde} texte={"Ouvrir"}
                                                          handleClick={() => alert("Ouvrir Traccar")} key={"KEY-GPS"}/>
                                        ) : ""}

                                        {permissionIsGranted(Number(orgaId), "Administrer_appareils") && (
                                            // <Modale_ajouter_appareil organisation={JSON.parse(JSON.stringify(orga))} setOrganisation={(orga_new) => {
                                            //     let unsave = false;
                                            //     if (JSON.stringify(orga) === JSON.stringify(unsaveOrga)) unsave = true
                                            //
                                            //
                                            //     if (orga_new.capteurs){
                                            //         let _nouveaux_capteurs:string[] = orga_new.capteurs.filter(nouv => {
                                            //             if (!orga || !orga.capteurs) return false;
                                            //             return orga.capteurs.filter(ancien => ancien.uuid === nouv.uuid).length === 0
                                            //         }).map(c => c.uuid)
                                            //         setNouveau_capteurs_a_surbriller(_nouveaux_capteurs)
                                            //     }
                                            //
                                            //     setOrga(orga_new)
                                            //     if (unsave) setUnsaveOrga(orga_new)
                                            //
                                            // }}/>
                                            <Modale_ajouter_appareil handleValiderAppareil={async (type_appareil, ref, nom) => {

                                                return new Promise((resolve, reject) => {
                                                    let save_orga_pointeur = (orga_new:Organisation) => {

                                                        let unsave = false;
                                                        if (JSON.stringify(orga) === JSON.stringify(unsaveOrga)) unsave = true


                                                        if (orga_new.capteurs){
                                                            let _nouveaux_capteurs:string[] = orga_new.capteurs.filter(nouv => {
                                                                if (!orga || !orga.capteurs) return false;
                                                                return orga.capteurs.filter(ancien => ancien.uuid === nouv.uuid).length === 0
                                                            }).map(c => c.uuid)
                                                            setNouveau_capteurs_a_surbriller(_nouveaux_capteurs)
                                                        }

                                                        setOrga(orga_new)
                                                        if (unsave) setUnsaveOrga(orga_new)

                                                    }

                                                    if (type_appareil?.startsWith("Capteur"))
                                                    {

                                                        ajax.put("/capteurs/" + ref, {
                                                            "nom": nom,
                                                            "organisation_id": orga.id
                                                        }).then(response => {
                                                            let orga_maj = JSON.parse(JSON.stringify(orga));
                                                            if (!orga_maj.capteurs) orga_maj.capteurs = [];
                                                            orga_maj.capteurs?.push(response.data.capteur)

                                                            save_orga_pointeur(orga_maj);
                                                            notifications.show({
                                                                withCloseButton: true,
                                                                autoClose: env_config.notifications_duree_affichage,
                                                                color: 'green',
                                                                title: "Appareil ajouté!",
                                                                message: 'Cliquez dessus pour le configurer',
                                                                icon: <IconeCheck/>,
                                                                onClick: () => {
                                                                    navigate("/capteur/" + ref)
                                                                }
                                                            });
                                                            resolve(true);
                                                        }).catch(error => {
                                                            resolve(false);
                                                        })
                                                    }
                                                    else if (type_appareil?.startsWith("Hub"))
                                                    {
                                                        ajax.put("/hubs/" + ref, {
                                                            "nom": nom,
                                                            "organisation_id": orga.id
                                                        }).then(response => {
                                                            let orga_maj = JSON.parse(JSON.stringify(orga));
                                                            if (!orga_maj.hubs) orga_maj.hubs = [];
                                                            orga_maj.hubs.push(response.data.hub)

                                                            save_orga_pointeur(orga_maj);
                                                            resolve(true);
                                                        }).catch(error => {
                                                            resolve(false);
                                                        })
                                                    }
                                                    else{
                                                        alert("Merci d'indiquer le type d'appareil")
                                                        resolve(false);
                                                    }
                                                });

                                            }}

                                            />
                                        )}

                                    </div>

                                </div>
                            )
                        }

                        const is_nouvelle_zone = (zone.nom === "Nouvelle zone");

                        return (
                            <div className={"zone" + (is_nouvelle_zone ? " nouvelle-zone" : "")}
                                 key={zone.id} id={"zone-" + zone.id}
                                 ref={is_nouvelle_zone || zone.id+""===queryParams.get("zone") ? scrollToRef : null}>
                                <div className={"en-tete"}>
                                    <h4>{zone.immatriculation ? "Véhicule" : "Zone"}</h4>

                                    {permissionIsGranted(Number(orgaId), "Administrer_organisation") && (
                                        <div className={"en-ligne"}>
                                            <button className={"sec"} onClick={() => handleDeleteZone(zone)}><IconeTrash/> Supprimer
                                            </button>
                                            {compareOrgaWithUnsave() && (
                                                <Loading_button onClick={handleSauvegarder} is_loading={btn_save_loading}
                                                                className={(!compareOrgaWithUnsave() ? "" : "red")}
                                                                icone={"floppy-disk"}>Enregistrer</Loading_button>
                                            )}

                                        </div>
                                    )}

                                </div>

                                <input type="text"
                                       value={zone.nom}
                                       onChange={event => {
                                           const maj = {...orga};
                                           if (maj.zones) maj.zones = maj.zones.map(_zone => {
                                               if (_zone.id === zone.id) {
                                                   let _z = {..._zone};
                                                   _z.nom = event.target.value;
                                                   return _z;
                                               }
                                               return _zone;
                                           })
                                           setOrga(maj);

                                       }
                                       }
                                />
                                <div className={"mesures"}>
                                    {zone.cellules?.map(cellule => {
                                        return (
                                            <Carte_mesure titre={cellule.nom}
                                                          sonde={cellule.sonde_type}
                                                          texte={cellule.last_mesures?.mesure}
                                                          key={cellule.id}
                                                          handleClick={() => {
                                                              if (controlKeyHeld) window.open("/cellule/" + cellule.id,'_blank');
                                                              else navigate("/cellule/" + cellule.id)
                                                          }}
                                                          // handleClick={() => {}}
                                                          conditions={cellule.statut_actuel?.statut.can_use_alertes ? cellule.conditions : []}
                                                          afficherTypeSiPasIcone={true}
                                                          afficherCondition={true}
                                                          affichage_vrai={cellule.affichage_vrai}
                                                          affichage_faux={cellule.affichage_faux}
                                                          afficherEtatExtension={true}
                                                          extension={cellule.extension}
                                                          commentaire_a_valider={!!cellule.commentaire_a_valider}
                                            />
                                        );
                                    })
                                    }
                                    {zone.immatriculation ? (
                                        <Carte_mesure titre={"Traceur GPS"} sonde={gps_sonde} texte={"Configurer"}
                                                      handleClick={() => alert("Ouvrir Traccar?")}/>
                                    ) : ""}


                                    {permissionIsGranted(Number(orgaId), "Administrer_cellule")
                                        && (orga && orga.capteurs && orga?.capteurs?.length > 0)
                                        && (
                                        <div className={"carte_mesure bleu white carte_mesure_creer_cellule"}
                                             onClick={() => handleAddCellule(zone.id)}>
                                            <div className={"titre"}>
                                                <div className={"titre_container"}>
                                                    <p className={"titre_texte"}>Créer une cellule</p>
                                                </div>
                                            </div>
                                            <p className={"valeur"}>{btn_add_cellule_loading ? <IconeLoader /> : <IconePlus />}</p>
                                        </div>
                                    )}




                                </div>

                            </div>
                        )
                    })}

                </div>

            </div>


            <Sidebar>
                <Bandeau_user/>
                {!permissionIsGranted(orga.id, "Administrer_organisation")
                && !permissionIsGranted(orga.id, "Gestion_alertes_toutes")
                && !permissionIsGranted(orga.id, "Administrer_anomalies") ? (
                    <div className={"actions"}>
                        <p className={"infos"}>Vous pouvez consulter cette organisation mais vous n'avez pas les autorisations d'administration.</p>
                    </div>

                ) : (
                    <div className={"actions"}>
                        <h3><IconeCircleInfo/> Informations de fonctionnement</h3>
                        {(orga.desactivation_texte !== null || orga.etat === "Desac_paiement") && (
                            <span className={"en-ligne"} style={{color: "var(--error-color)"}}><IconeCircleExclamation /> Organisation désactivée</span>
                        )}

                        <p className={"infos"}>Création : {afficherDateTime(new DateTZ(Date.parse(orga.creation)))}</p>
                        {/*<p className={"infos"}>Statut actuel : <button className={"sec"}><Icone*/}
                        {/*    nom={"tag"}/> {orga.statut.nom}</button></p>*/}
                        <h3><IconeGear/> Actions disponibles pour cette organisation</h3>

                        {permissionIsGranted(orga.id, "Administrer_organisation") && (
                            <div className={"carte_action"} id="carte_action_users">
                                <p className={"titre"}>Utilisateurs de l'organisation</p>
                                <p className={"infos"}>Voir la liste de tous les utilisateurs de la plateforme qui peuvent
                                    voir, modifier et/administrer votre organisation.</p>
                                <Modale_utilisateurs organisation={orga} setOrganisation={setOrga}/>
                            </div>
                        )}

                        <div className={"carte_action"}>
                            <p className={"titre"}>Editer en masse les anomalies</p>
                            <p className={"infos"}>Voir la liste des anomalies renseignées et modifier des valeurs en masse, comme, par exemple, les horaires de maintenance.</p>
                            <button className={"sec"} disabled={estDesactivee(orga)} onClick={() => navigate('/organisation/' + orgaId + "/anomalies")} ><IconeBells/> Anomalies</button>
                        </div>



                        {/*<div className={"carte_action"}>*/}
                        {/*    <p className={"titre"}>Changer le statut</p>*/}
                        {/*    <p className={"infos"}>Le statut permet d'obtenir des reductions sur le prix de service des*/}
                        {/*        données.</p>*/}
                        {/*    <button className={"sec"}><Icone nom={"gear"}/>Statut</button>*/}
                        {/*</div>*/}


                        {permissionIsGranted(orga.id, "Administrer_organisation") && (
                            <div className={"carte_action"}>
                                <p className={"titre"}>Paiements</p>
                                <div className={"tags"}>
                                    {orga.balance !== 0 && (
                                        <div className={"tag"} key={"condition-bal"}>
                                            Balance : {orga.balance} €
                                        </div>
                                    )}

                                    <div className={"tag"} key={"condition-sms"}>
                                    {orga.credit_SMS} SMS restants
                                    </div>
                                    <div className={"tag"} key={"condition-tel"}>
                                        {orga.credit_Tel} Appels restants
                                    </div>
                                    <div className={"tag"} key={"condition-proch"}>
                                        Prochain prélèvement le {afficherDate(orga.prochainPrelevement)}
                                    </div>
                                </div>
                                {typeof orga.orga_parent === "object" && orga.orga_parent != null ? (
                                    <>
                                        <Tooltip multiline w={"20rem"} label={"Définir une organisation comme parent transfert tout l'aspect paiement sur celle-ci, ce sera l'organisation parent qui va payer pour les cellules tout les mois, et c'est elle qui fourni les crédits SMS et Appels."}>
                                            <p className={"en-ligne"}><IconeCircleInfo /> Organisation parent </p>
                                        </Tooltip>
                                        <p>Organisation parent : <Link to={"/organisation/" + orga.orga_parent?.id}>{orga.orga_parent?.nom}</Link></p>
                                        <button className={"sec"} onClick={() => {
                                            let tpm = {...orga}
                                            tpm.orga_parent = 0;
                                            setOrga(tpm)
                                            //setTimeout(handleSauvegarder, 200)
                                        }} disabled={estDesactivee(orga)}>
                                            <IconeArrowUpLeftFromCircle/>Passer en organisation indépendante
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {/*<button className={"sec"}><IconeMessageMedical/>Recharger SMS</button>*/}
                                        <button className={"sec"} onClick={() => {navigate("/gestion")}} ><IconeEuroSign/>Paiement</button>


                                            <Tooltip multiline w={"20rem"} label={"Définir une organisation comme parent transfert tout l'aspect paiement sur celle-ci, ce sera l'organisation parent qui va payer pour les cellules tout les mois, et c'est elle qui fourni les crédits SMS et Appels."}>
                                                <p className={"en-ligne"}><IconeCircleInfo /> Organisation parent </p>
                                            </Tooltip>
                                        { orga.est_parent && (
                                            <p>Cette organisation est parent d'une ou plusieurs autre organisation.</p>
                                        )}
                                        {organisations_user_est_admin.length > 0 && !orga.est_parent && (
                                            <>
                                                <Menu shadow="md" width={200}>
                                                    <Menu.Target>
                                                        <button className={"sec"} disabled={estDesactivee(orga)}>
                                                            <IconeArrowUpLeftFromCircle/>Définir une organisation parent
                                                        </button>
                                                    </Menu.Target>

                                                    <Menu.Dropdown>
                                                        <Menu.Label>Vers l'organisation...</Menu.Label>
                                                        {organisations_user_est_admin.map(_orga => {
                                                            return (
                                                                <Menu.Item onClick={() => {
                                                                    let tpm = {...orga}
                                                                    tpm.orga_parent = _orga;
                                                                    setOrga(tpm)
                                                                    //setTimeout(handleSauvegarder, 200)
                                                                }}>
                                                                    {_orga.nom}
                                                                </Menu.Item>
                                                            )
                                                        })}
                                                    </Menu.Dropdown>
                                                </Menu>
                                            </>
                                        )}

                                    </>
                                )}


                            </div>
                        )}


                        {(user.roles.includes("ROLE_PRESTATAIRE")) && (
                            <>
                                <div className={"carte_action prestataire"}>
                                    <p className={"titre"}>Changer la date de prélèvement</p>
                                    <p className={"infos"}>La date de prélèvement de l'organisation est la date qui activera l'organisation et qui sera répeté tous les mois.</p>
                                    <Modale_changer_date_prelevement organisation={orga} setOrganisation={setOrga} />
                                </div>
                                <div className={"carte_action prestataire"}>
                                    <p className={"titre"}>Désactiver l'organisation</p>
                                    <p className={"infos"}>Prestataire seulement<br />Une organisation désactivée devient inaccessible pour les utilisateurs, et les alertes ne fonctionnent plus.</p>
                                    <Modale_desactiver_organisation organisation={orga} setOrganisation={setOrga} />
                                </div>
                                <div className={"carte_action prestataire"}>
                                    <p className={"titre"}>Supprimer l'organisation</p>
                                    <p className={"infos"}>Prestataire seulement<br />Supprimer une organisation va supprimer toutes les informations associés : mesures, conditions, status, ... . Cette action est irréversible.</p>
                                    <button onClick={handleDeleteOrganisation} className={"sec"}><IconeTrashCan/>Supprimer</button>
                                </div>
                            </>
                        )}


                    </div>
                )}
            </Sidebar>


            <Joyride
                steps={tourIntro}
                stepIndex={tourStepIndex}
                callback={handleJoyrideCallback}
                run={tourRun}
                styles={{
                    options: {
                        // arrowColor: '#e3ffeb',
                        // backgroundColor: '#e3ffeb',
                        // overlayColor: 'rgba(79, 26, 0, 0.4)',
                        primaryColor: 'var(--main-color)',
                        zIndex: 100000
                    },
                    tooltipContainer: {
                        lineHeight: 1.4,
                        textAlign: 'left',
                    },
                }}
                locale={{ back: 'Retour', close: 'Fermer', last: 'Fermer', next: 'Suivant', open: 'Ouvrir l\'aide', skip: 'Passer' }}
                continuous
                showProgress
            />

            <Orgadmin_intro_tour organisation={orga} />
            <Orgadmin_presenter_zones organisation={orga} />

            {pasApas.tour === null && nbe_cellules > 2 && (
                <Modale_promo_nbe_capteurs organisation_id={orga.id} children={<></>} forcer={true} />
            )}


        </div>

    );
}

export function estDesactivee(organisation:Organisation|Organisation_paiement_orga_detail){
    return ((organisation.desactivation_texte !== "" && organisation.desactivation_texte !== null) || organisation.etat === "Desac_paiement")
}