import {Cellule} from "../../../../types/cellule";
import React, {useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../../services/AxiosInterceptor";
import {Mark, Modal, Switch, TextInput, Tooltip, Text} from "@mantine/core";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {IconeTag} from "../../../../components/icone/solid_react/tag";
import {IconeChartWaterfall} from "../../../../components/icone/solid_react/chart-waterfall";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeFloppyDisk} from "../../../../components/icone/solid_react/floppy-disk";
import {condition_unit} from "./modale_configurer_conditions";
import {permissionIsGranted} from "../../../../services/permissionsService";
import {IconeArrowRotateLeft} from "../../../../components/icone/solid_react/arrow-rotate-left";
import {IconeI} from "../../../../components/icone/solid_react/i";
import {IconeInfo} from "../../../../components/icone/solid_react/info";

export function Modale_analyses({
                                       cellule,
                                       setCellule
                                   }: { cellule: Cellule, setCellule: React.Dispatch<Cellule | null> }) {
    const [opened, handlers] = useDisclosure(false);
    const [save_btn_loading, set_save_btn_loading] = useState(false);
    const [nom, set_nom] = useState(cellule.nom);

    const [analyses, setAnalyses] = useState(cellule.analyses);

    const [activer_anomalie_compresseur, setActiver_anomalie_compresseur] = useState<boolean>(false); //cellule.conditions.find(c => c.valeur_a_comparer === "55") !== undefined ?? false

    useEffect(() => {
        if (!opened) setAnalyses(cellule.analyses)
    }, [cellule]);


    // if (forcer) handlers.open();

    function handleEnregistrer(reinit_ref?:boolean) {
        set_save_btn_loading(true);
        let _edit_cellule = {...cellule, "analyses": analyses};
        if (activer_anomalie_compresseur){
            _edit_cellule.config_rapide = false;
        }
        if (reinit_ref){
            _edit_cellule.analyses_reference = null;
        }
        ajax.put("/cellules/" + cellule.id, _edit_cellule).then(response => {
            if (activer_anomalie_compresseur){
                setActiver_anomalie_compresseur(false)
                let conditions:condition_unit[] = [];
                cellule.conditions.filter(c => !c.is_hidden).map(c => {
                    conditions.push({
                        "index": c.id,
                        "nom": c.nom,
                        "texte_a_afficher": c.texte_a_afficher,
                        "type": c.slug + "",
                        "valeur": Number(c.valeur_a_comparer),
                        "duree": c.delais,
                        "horaire": c.horaires_maintenance,
                        repetition: c.repetition,
                        doitGenererCommentaire: c.doitGenererCommentaire,
                        "alertes": [] //Est là pour le front, le back ignore les alertes ici
                    })
                })
                _edit_cellule.conditions = [..._edit_cellule.conditions];
                conditions.push({
                    "index": -5,
                    "nom": "Condenseur chaud",
                    "texte_a_afficher": "Cellule "+cellule.nom+" : Température du condensateur élevé, à surveiller.",
                    "type": "1",
                    "valeur": 50,
                    "duree": 35,
                    "horaire": [],
                    repetition: false,
                    doitGenererCommentaire: false,
                    "alertes": [] //Est là pour le front, le back ignore les alertes ici
                })
                _edit_cellule.conditions.push({
                    alertes: [],
                    cellule_id: cellule.id,
                    changement_etat: "",
                    delais: 35,
                    horaires_maintenance: [],
                    id: -5,
                    is_hidden: false,
                    nom: "Condenseur chaud",
                    repetition: false,
                    doitGenererCommentaire: false,
                    slug: 1,
                    statut_validation: 0,
                    texte_a_afficher: "Cellule "+cellule.nom+" : Température du condensateur élevé, à surveiller.",
                    valeur_a_comparer: "50",
                    validation: "",
                })
                conditions.push({
                    "index": -6,
                    "nom": "Température critique condenseur",
                    "texte_a_afficher": "Cellule "+cellule.nom+", danger : Le groupe de froid fonctionne en hors limite et est à risque d'arrêt.",
                    "type": "1",
                    "valeur": 55,
                    "duree": 1,
                    "horaire": [],
                    repetition: false,
                    doitGenererCommentaire: false,
                    "alertes": [] //Est là pour le front, le back ignore les alertes ici
                })
                _edit_cellule.conditions.push({
                    alertes: [],
                    cellule_id: cellule.id,
                    changement_etat: "",
                    delais: 0,
                    horaires_maintenance: [],
                    id: -6,
                    is_hidden: false,
                    nom: "Température critique condenseur",
                    repetition: false,
                    slug: 1,
                    statut_validation: 0,
                    texte_a_afficher: "Cellule "+cellule.nom+", danger : Le groupe de froid fonctionne en hors limite et est à risque d'arrêt.",
                    valeur_a_comparer: "55",
                    validation: "",
                    doitGenererCommentaire: false
                })


                ajax.put('/cellules/' + cellule.id + '/conditions', {
                    conditions: conditions
                }).then(response_cond => {

                        handlers.close();
                        set_save_btn_loading(false);
                    }).catch(error => {
                    set_save_btn_loading(false);
                })
            }
            else{
                handlers.close();
                set_save_btn_loading(false);
            }


            setCellule(_edit_cellule);
        }).catch(error => {
            set_save_btn_loading(false);
        })

    }

    return (
        <>
            <button className={"sec"} onClick={handlers.toggle}><IconeChartWaterfall/>Analyses
            </button>
            <Modal opened={opened} onClose={handlers.close} title="Analyses intelligentes disponibles">
                <div className={"form"}>
                    {cellule.statut_actuel?.statut.can_use_fonctions_avancees && [{
                        nom: "Alerte Surveillance Frigorifique",
                        slug: "surchauffe",
                        desc: "Cette analyse va mesurer une " + cellule.sonde_type?.affichage_nom_mesure.toLowerCase() + " de référence, puis surveiller et vous alerter si la moyenne des derniers jours s'en éloigne trop.",
                    },{
                        nom: "Risque de congélation",
                        slug: "congelation",
                        desc: "Cette analyse va estimer les plages de " + cellule.sonde_type?.affichage_nom_mesure.toLowerCase() + " nominales de votre chambre froide, pour vous prévenir si la " + cellule.sonde_type?.affichage_nom_mesure.toLowerCase() + " moyenne sur plusieurs jours descend trop et risque provoquer prochainement une congélation des aliments.",
                    }].map(analyse => (
                        <div style={{marginBottom: "1rem", minWidth: "40vw"}} key={analyse.slug}>
                            <div className={"en-ligne spacebetween"}>
                                <strong className={"en-ligne no-wrap"} style={{gap: "1rem"}}>{analyses?.includes(analyse.slug) ? <IconeCheck className={"green"}/> : <></>}{analyse.nom}</strong>

                                <button onClick={() => {
                                    let _edit:string[]|null = analyses ? [...analyses] : null
                                    _edit = _edit?.filter(a => a !== analyse.slug) ?? null
                                    if (!analyses?.includes(analyse.slug)) {
                                        if (_edit === null) _edit = [analyse.slug]
                                        else _edit.push(analyse.slug)
                                    }
                                    setAnalyses(_edit);
                                }
                                } className={analyses?.includes(analyse.slug) ? "red" : "sec"}>
                                    {analyses?.includes(analyse.slug) ? <>Désactiver</> : <>Activer</>}
                                </button>
                            </div>
                            <p style={{marginTop: "0.2rem", fontStyle: "italic"}}>{analyse.desc}</p>
                        </div>
                    ))}



                    <div style={{marginBottom: "1rem", minWidth: "40vw"}}>
                        <div className={"en-ligne spacebetween"}>
                            <strong className={"en-ligne no-wrap"} style={{gap: "1rem"}}>{(cellule.conditions.find(c => c.valeur_a_comparer === "55") !== undefined || activer_anomalie_compresseur) ? <IconeCheck className={"green"}/> : <></>}Anomalies température condenseur élevé</strong>

                            {!cellule.conditions.find(c => c.valeur_a_comparer === "55") && (
                                <button onClick={() => {
                                    setActiver_anomalie_compresseur(!activer_anomalie_compresseur)
                                }
                                } className={activer_anomalie_compresseur ? "red" : "sec"}>
                                    {activer_anomalie_compresseur ? <>Désactiver</> : <>Activer</>}
                                </button>
                            )}

                        </div>
                        <p style={{marginTop: "0.2rem", fontStyle: "italic"}}>Cette option est réservé pour les cellules mesurant la température du radiateur de refroidissement du moteur. Deux anomalies seront programmées à 50°C et 55°C, qui se déclencheront immédiatement si votre condenseur atteint ces températures, qui sont signe d'un soucis technique important. Pensez à activer les alertes pour être informé sur le canal de votre choix.</p>
                    </div>


                    {cellule.analyses_reference ? (
                        <p className={"inline-tag green"}>Les analyses sont actives</p>
                    ) : (
                        <p className={"inline-tag red"}>
                            <Text style={{fontSize: "var(--texte-size-petit)"}}>
                                Le système est en train de collecter les données nécessaires pour les analyses.
                                <br />Un certain nombre et une certaine stabilité des mesures sont requises pour pouvoir
                                prendre les mesures de références requises aux analyses.
                                <br />Ce processus peut prendre jusqu'à plusieurs jours.
                                <br />Si le processus n'aboutit pas au bout de 2 semaines, il est possible que{" "}
                                <Tooltip inline={true} label={
                                    <>
                                         Les mesures sont trop instables si l'écart type des 700 dernières mesures est supérieur à 3.
                                        <br />Les causes courantes sont des portes trop souvent ouvertes ou ouvertes trop longtemps.
                                        <br />Une solution courante est de mettre la sonde dans un liquide neutre, comme de l'huile.
                                    </>
                                }><Mark>les mesures soient trop instables.</Mark></Tooltip>

                                <br />Dans ce cas, n'hésitez pas à nous contacter.
                            </Text>

                        </p>
                    )}

                    {permissionIsGranted(undefined, "ADMIN") && (
                        <p>Température de référence : {cellule.analyses_reference ?? "Pas encore appliqué"}</p>
                    )}

                    {!permissionIsGranted(cellule.organisation.id, "recevoir_email_analyses") && (
                        <p className={"notice"}>Votre compte n'est pas configuré pour recevoir les emails des analyses.
                            Pour configurer quels utilisateurs doivent recevoir les emails, vous devez vous rendre sur la liste des utilisateurs de
                            l'organisation et activer l'option situé avec les permissions, pour chaque utilisateur.
                        </p>
                    )}


                    <div className={"en-ligne spaceevenly"}>
                        {cellule.analyses_reference && (
                            <Loading_button is_loading={save_btn_loading} className={"sec"}
                                            onClick={() => {
                                                handleEnregistrer(true)
                                            }
                                            }><IconeArrowRotateLeft /> Réinitialiser les valeurs des analyses</Loading_button>
                        )}
                        
                        <Loading_button is_loading={save_btn_loading}
                                        onClick={handleEnregistrer}><IconeFloppyDisk /> Enregistrer</Loading_button>
                    </div>


                </div>

            </Modal>
        </>

    )
}