import React, {forwardRef, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Autocomplete, Avatar, ComboboxItem, ComboboxItemGroup,
    Group,
    Modal,
    NativeSelect,
    Select,
    Switch,
    Textarea,
    TextInput
} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Hub} from "../../../types/hub";
import {IconeMagnifyingGlass} from "../../../components/icone/solid_react/magnifying-glass";
import {permissionIsGranted} from "../../../services/permissionsService";
import {IconeTrash} from "../../../components/icone/solid_react/trash";
import {Organisation} from "../../../types/organisation";
import {Cellule} from "../../../types/cellule";
import {IconePlus} from "../../../components/icone/solid_react/plus";
import {setPasApasEtat} from "../../../store/pasApasSlice";
import {useDispatch} from "react-redux";
import {Commentaire} from "../../../types/commentaire";
import {DateTimePicker} from "@mantine/dates";
import {DateTZ} from "../../../services/GestionDates";

export function Commentaire_creer({organisation, commentaires_precedents, refresh, commentaire, mode_edit, forcer = null}: {
    organisation: Organisation,
    commentaires_precedents: string[],
    refresh: () => void,
    commentaire: Commentaire|null,
    mode_edit: boolean,
    forcer?: string|null,
}) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);

    const [cellule_selected, setCellule_selected] = useState<string>(commentaire?.cellule.id+"" ?? "null");
    const [commentaire_txt, setCommentaire_txt] = useState(commentaire?.commentaire ?? "");
    const [debut, setDebut] = useState(commentaire?.debut ?? new DateTZ().toISOString());
    const [fin, setFin] = useState(commentaire?.fin ?? new DateTZ().toISOString());
    const [afficherDansRapport, setAfficherDansRapport] = useState(commentaire?.afficherDansRapport ?? true);
    const [estValiderClient, setEstValiderClient] = useState(commentaire?.estValiderClient ?? true);

    // useEffect(() => {
    //     let capteurs_en_commentaires = organisation.capteurs?.filter(c => c.categorie_abonnement === "Commentaire").map(c => c.uuid) ?? [];
    //     let _cells:Cellule[] = [];
    //     organisation.zones?.forEach(z => z.cellules?.filter(c => capteurs_en_commentaires.includes(c.extension?.capteur.uuid ?? "aucun_capteur")).map(c => _cells.push(c)));
    //     setCellules_en_commentaires(_cells);
    // }, [organisation]);

    useEffect(() => {
        if (commentaire && mode_edit)
        {
            setCommentaire_txt(commentaire?.commentaire ?? "");
            setDebut(commentaire?.debut ?? new DateTZ().toISOString());
            setFin(commentaire?.fin ?? new DateTZ().toISOString());
            setAfficherDansRapport(commentaire?.afficherDansRapport ?? true);
            setEstValiderClient(commentaire?.estValiderClient ?? true);
            setCellule_selected(commentaire?.cellule.id+"" ?? "null");
            handlers.open();
        }
    }, [commentaire]);

    useEffect(() => {
        if (forcer) {
            setCellule_selected(forcer);
            handlers.open();
        }
    }, [forcer]);

    function handleEnregistrer() {
        set_btn_loading(true);

        let data= {
            cellule: {
                id: cellule_selected
            },
            debut: debut,
            estValiderClient: estValiderClient,
            fin: fin,
            id: commentaire?.id ?? -1,
            commentaire: commentaire_txt,
            afficherDansRapport: afficherDansRapport
        }

        if (commentaire)
        {
            ajax.put("/commentaires/" + commentaire.id, data).then(response => {

                dispatch(setPasApasEtat("modale_commentaire_valide"))
                handleClose();
                set_btn_loading(false);
            }).catch(error => {
                set_btn_loading(false);
            })
        }
        else{
            ajax.post("/cellules/" + cellule_selected + "/commentaires", data).then(response => {

                dispatch(setPasApasEtat("modale_commentaire_valide"))
                handleClose();
                set_btn_loading(false);
            }).catch(error => {
                set_btn_loading(false);
            })
        }



    }
    function handleDelete() {
        set_btn_loading(true);

        if (commentaire)
        {
            ajax.delete("/commentaires/" + commentaire.id).then(response => {

                dispatch(setPasApasEtat("modale_commentaire_valide"))
                handleClose();
                set_btn_loading(false);
            }).catch(error => {
                set_btn_loading(false);
            })
        }

    }

    function handleClose() {
        dispatch(setPasApasEtat("modale_commentaire_close"))
        handlers.close();
        setTimeout(refresh, 500);
    }

    let cellules_a_afficher: (ComboboxItemGroup)[] = [{group: '-- Zone', items: [{label: "-- Cellule", value: "null"}] }];
    organisation.zones?.filter(z => !z.nom.includes("base.")).forEach((zone, i) => {
        let group:(ComboboxItem)[] = [];
        zone.cellules_minimum?.filter(c => !c.desactivee).forEach(c => {
            group.push({label: c.nom, value: c.id});
        })
        cellules_a_afficher.push({group: zone.nom, items : group});

    })

    const [vue_suivante, setVue_suivante] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (opened) dispatch(setPasApasEtat("modale_commentaire_opened"))
    }, [opened]);

    return (
        <>

            {!mode_edit && (
                <button id={"btn_creer_commentaire"} onClick={handlers.toggle}><IconePlus/> Créer un commentaire
                </button>
            )}


            <Modal opened={opened} onClose={() => handleClose()} title={commentaire ? "Modifier un commentaire" : "Créer un commentaire"}>


                <div className={"form"} id={"modale_creer_commentaire_form"}>

                <NativeSelect
                        // data={ cellules_en_commentaires.map(c => {
                        //     return {label: c.nom, value: c.id+""};
                        // })}
                        data={cellules_a_afficher}
                        id={"modale_creer_commentaire_cellule"}
                        label={"Cellule"}
                        value={cellule_selected ?? ""}
                        onChange={e => {
                            setCellule_selected(e.target.value);
                        }}
                    />
                    <Autocomplete
                        data={[...new Set(commentaires_precedents)]}
                        label={"Commentaire"}
                        id={"modale_creer_commentaire_plat"}
                        value={commentaire_txt ?? ""}
                        onChange={e => {
                            setCommentaire_txt(e);
                        }}
                    />
                    <DateTimePicker
                        locale={"Fr"}
                        label={"Début"}
                        value={new DateTZ(debut)}
                        onChange={e => {
                            setDebut(e?.toISOString() ?? "");
                        }}
                    />
                    <DateTimePicker
                        locale={"Fr"}
                        label={"Fin"}
                        value={new DateTZ(fin)}
                        onChange={e => {
                            setFin(e?.toISOString() ?? "");
                        }}
                    />
                    <div id={"modale_creer_commentaire_switch"}>
                        <Switch
                            label={"Afficher sur les rapports"}
                            checked={afficherDansRapport}
                            onChange={e => {
                                setAfficherDansRapport(e.target.checked);
                            }}
                        />
                        <Switch
                            label={"Est validé"}
                            checked={estValiderClient}
                            onChange={e => {
                                setEstValiderClient(e.target.checked);
                            }}
                        />
                    </div>



                    <div className={"en-ligne spaceevenly"} id={"modale_creer_commentaire_valider"}>

                        {commentaire && (
                            <Loading_button className={"sec rouge"} is_loading={btn_loading}
                                            onClick={handleDelete} icone={"trash"}>Supprimer</Loading_button>
                        )}

                        <Loading_button is_loading={btn_loading} disabled={cellule_selected === "null" || commentaire_txt.length === 0}
                                        onClick={handleEnregistrer} icone={"check"}>Enregistrer</Loading_button>
                    </div>




                </div>
                

            </Modal>
        </>

    )
}