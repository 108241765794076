import env_config from "../env_config";
import {DateValue} from "@mantine/dates";
import {store} from "../store/store";

export class DateTZ extends Date {
    timezoneOffset: number;
    use_parent_class: boolean;

    constructor(...args: any[]) {
        // @ts-ignore
        super(...args);

        this.timezoneOffset = store.getState().user.timezone ?? 0;
        this.use_parent_class = store.getState().user.timezone === null;
    }

    // Override the getHours method to consider the timezone
    getHours(): number {
        if (this.use_parent_class) return super.getHours();
        return (this.getUTCHours() + this.timezoneOffset / 60 +24) % 24;
    }

    // Similarly override other getter methods as needed

    getMinutes(): number {
        if (this.use_parent_class) return super.getMinutes();
        return (this.getUTCMinutes() + this.timezoneOffset % 60) % 60;
    }

    getSeconds(): number {
        if (this.use_parent_class) return super.getSeconds();
        return this.getUTCSeconds(); // Seconds are usually not timezone-dependent
    }

    getDate(): number {
        if (this.use_parent_class) return super.getDate();
        const utcDate = new Date(this.getTime() + this.timezoneOffset * 60000);
        return utcDate.getUTCDate();
    }

    getDay(): number {
        if (this.use_parent_class) return super.getDay();
        const utcDate = new Date(this.getTime() + this.timezoneOffset * 60000);
        return utcDate.getUTCDay();
    }

    getMonth(): number {
        if (this.use_parent_class) return super.getMonth();
        const utcDate = new Date(this.getTime() + this.timezoneOffset * 60000);
        return utcDate.getUTCMonth();
    }

    getFullYear(): number {
        if (this.use_parent_class) return super.getFullYear();
        const utcDate = new Date(this.getTime() + this.timezoneOffset * 60000);
        return utcDate.getUTCFullYear();
    }

    getApplyiedTimezoneOffset(): number {
        if (this.use_parent_class) return super.getTimezoneOffset();
        return this.timezoneOffset;
    }

    // Override the toISOString method to consider the timezone
    toISOString(): string {
        if (this.use_parent_class) return super.toISOString();
        const offsetDate = new Date(this.getTime() - this.timezoneOffset * 60000);
        const year = offsetDate.getUTCFullYear();
        const month = String(offsetDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(offsetDate.getUTCDate()).padStart(2, '0');
        const hours = String(offsetDate.getUTCHours()).padStart(2, '0');
        const minutes = String(offsetDate.getUTCMinutes()).padStart(2, '0');
        const seconds = String(offsetDate.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(offsetDate.getUTCMilliseconds()).padStart(3, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    }

    addHours(hours: number): void {
        if (this.use_parent_class)
        {
            super.setHours(super.getHours() + hours);
        }
        else{
            const heureUTC = this.getUTCHours() + this.timezoneOffset / 60;
            const adjustedHours = hours; // + this.timezoneOffset / 60

            if (heureUTC + adjustedHours >= 24) this.setDate(this.getDate() + 1);

            this.setUTCHours(adjustedHours + this.getHours())
        }

    }

    setHours(hours: number, ...args: number[]): number {
        if (this.use_parent_class) return super.setHours(hours, ...args);
        const adjustedHours = hours + this.timezoneOffset / 60;
        // return super.setUTCHours(adjustedHours, ...args);
        if (this.getHours() + adjustedHours >= 24) this.setDate(this.getDate() + 1);
        return super.setUTCHours(hours, ...args);
    }

    setMinutes(minutes: number, ...args: number[]): number {
        if (this.use_parent_class) return super.setMinutes(minutes, ...args);
        const adjustedMinutes = minutes - (this.timezoneOffset % 60);
        return super.setUTCMinutes(adjustedMinutes, ...args);
    }

    setSeconds(seconds: number, ...args: number[]): number {
        if (this.use_parent_class) return super.setSeconds(seconds, ...args);
        return super.setUTCSeconds(seconds, ...args);
    }

    setDate(date: number): number {
        if (this.use_parent_class) return super.setDate(date);
        const offsetDate = new Date(this.getTime() + this.timezoneOffset * 60000);
        offsetDate.setUTCDate(date);
        return this.setTime(offsetDate.getTime() - this.timezoneOffset * 60000);
    }

    setMonth(month: number, date?: number): number {
        if (this.use_parent_class) {
            if (date) return super.setMonth(month, date);
            else return super.setMonth(month);
        }
        const offsetDate = new Date(this.getTime() + this.timezoneOffset * 60000);
        if (date) offsetDate.setUTCMonth(month, date);
        else offsetDate.setUTCMonth(month);
        return this.setTime(offsetDate.getTime() - this.timezoneOffset * 60000);
    }

    setFullYear(year: number, month?: number, date?: number): number {
        if (this.use_parent_class) return super.setFullYear(year, month, date);
        const offsetDate = new Date(this.getTime() + this.timezoneOffset * 60000);
        offsetDate.setUTCFullYear(year, month, date);
        return this.setTime(offsetDate.getTime() - this.timezoneOffset * 60000);
    }
}

export function afficherHeure(heure:number, minute:number, secondes?:number)
{
    let texte = heure.toString().padStart(2, '0') + ":" + minute.toString().padStart(2, '0')
    if (secondes) {
        texte = heure.toString().padStart(2, '0') + "h" + minute.toString().padStart(2, '0') + " " + secondes.toString().padStart(2, '0') + "s"
    }
    return texte
}


export function afficherDateTime(heure:Date|string|undefined|null)
{
    if (typeof heure === "undefined" || heure===null)
    {
        return "Jamais"
    }
    if (typeof heure === "string")
    {
        heure = new DateTZ(heure)
    }
    let date = heure.getDate().toString().padStart(2, '0') + "/" + (heure.getMonth() + 1).toString().padStart(2, '0') + "/" + heure.getFullYear().toString();
    let mins = heure.getHours().toString().padStart(2, '0') + ":" + heure.getMinutes().toString().padStart(2, '0');
    return date + " " + mins;
}

export function afficherDate(heure:Date|string|undefined|null)
{
    if (typeof heure === "undefined" || heure===null)
    {
        return "Jamais"
    }
    if (typeof heure === "string")
    {
        heure = new DateTZ(heure)
    }
    let date = heure.getDate().toString().padStart(2, '0') + "/" + (heure.getMonth() + 1).toString().padStart(2, '0') + "/" + heure.getFullYear().toString();
    return date;
}

export function afficherDateTimeSecondes(heure:Date|string|undefined|null)
{
    if (typeof heure === "undefined" || heure===null)
    {
        return "Jamais"
    }
    if (typeof heure === "string")
    {
        heure = new DateTZ(heure)
    }
    let date = heure.getDate().toString().padStart(2, '0') + "/" + (heure.getMonth() + 1).toString().padStart(2, '0') + "/" + heure.getFullYear().toString();
    let mins = heure.getHours().toString().padStart(2, '0') + ":" + heure.getMinutes().toString().padStart(2, '0') + ":" + heure.getSeconds().toString().padStart(2, '0');
    return date + " " + mins;
}

export function afficherDateTimeSecondesUTC(heure:Date|string|undefined|null)
{
    if (typeof heure === "undefined" || heure===null)
    {
        return "Jamais"
    }
    if (typeof heure === "string")
    {
        heure = new DateTZ(heure)
    }
    let date = heure.getUTCDate().toString().padStart(2, '0') + "/" + (heure.getUTCMonth() + 1).toString().padStart(2, '0') + "/" + heure.getUTCFullYear().toString();
    let mins = heure.getUTCHours().toString().padStart(2, '0') + ":" + heure.getUTCMinutes().toString().padStart(2, '0') + ":" + heure.getUTCSeconds().toString().padStart(2, '0');
    return date + " " + mins;
}

export function getCurrentDate()
{
    return new DateTZ();
}

export function dateTimePickerValueWithoutTimezone(value?:DateValue)
{
    if (value && store.getState().user.timezone !== null)
    {
        // alert("in : " + value.getUTCHours())
        let new_val = new DateTZ(value.getTime());
        // alert("in : " + new_val.getUTCHours())

        new_val.setHours(new_val.getUTCHours() + value.getTimezoneOffset()/60)
        // alert("in : " + value.getTimezoneOffset())
        // alert("in : " + new_val.getUTCHours())
        // alert(value.getTimezoneOffset())
        return new_val;
    }
    else return value;
}

export function afficherHeureDepuisMinutes(minutes_total:number)
{

    let heure = Math.floor(minutes_total/60);
    let minutes = Math.floor(minutes_total%60);

    if (heure === 0){
        return minutes + " minutes";
    } else{
        return heure + "h " + minutes.toString().padStart(2, '0') + "min";
    }


}
