import {Navbar} from "../../../components/navbar/navbar";
import React, {useEffect, useState} from "react";

import './Contacts.scss'
import {Modal, Tooltip} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {Bandeau_user} from "../../../components/sidebar/bandeau_user/bandeau_user";
import {setMobile_sidebar_show} from "../../../store/interfaceSlice";
import {Organisation} from "../../../types/organisation";
import {Tableau_helper, typeDonnee} from "../../../components/tableau/Tableau_helper";
import {Statut_cellule} from "../../../types/statut_cellule";
import {Contact} from "../../../types/contact";
import {AxiosResponse} from "axios";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import env_config from "../../../env_config";
import {notifications} from "@mantine/notifications";
import ajax from "../../../services/AxiosInterceptor";
import {IconeNarwhal} from "../../../components/icone/solid_react/narwhal";
import {IconeUpRightFromSquare} from "../../../components/icone/solid_react/up-right-from-square";
import {useDisclosure} from "@mantine/hooks";
import {editOrganisation} from "../../../store/organisationSlice";


export function Contacts() {

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)


    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_show(false))

    const [donnees, setDonnees] = useState<Contact[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    const [notesModal, setNotesModal] = useState<string|null>(null);

    const Statut_Cellule_Tableau_admin:typeDonnee[] = [
        {key: "organisation",
            nom: "Organisation",
            type: "select",
            select_data:organisations.map(o => {
            return {value: o.id + "", label: o.nom}
        }), wrapper: (texte:string) => {
            let orga = organisations.filter(o => o.id + "" == texte)[0];
            return (
                    <p>{orga ? orga.nom : "Freshtime"}</p>)
            },
            required: true,
            uniquement_new: true
        },
        {key: "societe", nom: "Société"},
        {key: "nom", nom: "Nom"},
        {key: "tel", nom: "Tél", wrapper: (texte:string) => (
                <a className={"no-wrap"} href={"tel:" + texte}>{texte}</a>)},
        {key: "mobile", nom: "Mobile", wrapper: (texte:string) => (
                <a className={"no-wrap"} href={"tel:" + texte}>{texte}</a>)},
        {key: "email", nom: "Email", wrapper: (texte:string) => (
                <a className={"no-wrap"} href={"mailto:" + texte}>{texte}</a>)},
        {key: "site_web", nom: "Site Web", wrapper: (texte:string) => (
            <>
                {texte != "" && (
                    <Tooltip label={texte}>
                        <button className={"sec"}><a href={texte}><IconeUpRightFromSquare /></a></button>
                    </Tooltip>


                )}

            </>)},
        {key: "notes", nom:"Notes", wrapper: (texte:string) => (
                <Tooltip label={texte} onClick={() => setNotesModal(texte)}>
                    <pre className={"inline-tag"}>{texte.length < 60 ? texte : texte.slice(0, 57) + "..."}</pre>
                </Tooltip>),
            spanClassname: "flex_align_center",
            type: "textarea"
        },
        {action_editer: true, cacherDansTableSi: (row) => (row.non_editable)},
    ]

    useEffect(() => {
        let contacts:Contact[] = [
            {
                nom: "Yoann Bemon",
                societe: "Freshtime",
                organisation: "0",
                tel: "+33 6 49 83 15 98",
                mobile: "",
                site_web: "https://freshtime.fr",
                email: "contact@freshtime.fr",
                notes: "Votre contact Freshtime",
                id: "non_editable",
                non_editable:true
            }
        ];
        organisations.map(o => {
            o.contacts && o.contacts.map(c => {
                contacts.push(c)
            })
        })
        setDonnees(contacts)
    }, [organisations]);

    function handleEnregistrerStatut(contact:Contact|null, setIsLoading:(isLoading:boolean) => void) {

        if (!contact)
        {
            alert( "Erreur, contact est : " + JSON.stringify(contact))
            return;
        }
        setIsLoading(true);
        let nouveau = !contact?.id;
        let ajax_response = (response:AxiosResponse<any, any>) => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'Le contact est enregistré',
                icon: <IconeCheck/>,
                // className: 'my-notification-class',
                loading: false,
            });
            setIsLoading(false);

            if (nouveau){
                if (donnees) {
                    // setDonnees([...donnees, contact])
                    let orga_edit = {...organisations.filter(o => o.id + "" === contact.organisation)[0]}
                    orga_edit.contacts = [...orga_edit.contacts, contact]
                    dispatch(editOrganisation(orga_edit))
                }
            }else{
                if (donnees) {
                    // setDonnees(donnees?.map(donnee => {
                    //     if (donnee.id === contact.id){
                    //         return contact
                    //     }
                    //     return donnee;
                    // }));

                    let orga_edit = {...organisations.filter(o => o.id + "" === contact.organisation)[0]}
                    orga_edit.contacts = orga_edit.contacts.map(donnee => {
                        if (donnee.id === contact.id){
                            return contact
                        }
                        return donnee;
                    })
                    dispatch(editOrganisation(orga_edit))
                }
            }

        }
        if (nouveau)
        {
            contact.id = Date.now() + "-" + Math.floor(Math.random() * 1000);
            ajax.post("/organisations/" + contact.organisation + "/contact", contact).then(ajax_response).catch(error => {
                setIsLoading(false);
            });
        } else{
            ajax.put("/organisations/" + contact.organisation + "/contact", contact).then(ajax_response).catch(error => {
                setIsLoading(false);
            });
        }

    }

    function handleSupprimerStatut(contact:Contact|null, setIsLoading:(isLoading:boolean) => void) {

        if (!contact)
        {
            alert( "Erreur, contact est : " + JSON.stringify(contact))
            return;
        }
        setIsLoading(true);
            ajax.delete("/organisations/" + contact.organisation + "/contact", {
                data: contact}).then((response:AxiosResponse<any, any>) => {
                notifications.show({
                    // id: 'enregistre',
                    withCloseButton: true,
                    autoClose: env_config.notifications_duree_affichage,
                    color: 'orange',
                    title: "Validé!",
                    message: 'Le contact a été supprimé',
                    icon: <IconeCheck/>,
                    // className: 'my-notification-class',
                    loading: false,
                });
                setIsLoading(false);

                // setDonnees(donnees?.filter(donnee => donnee.id !== contact.id))
                let orga_edit = {...organisations.filter(o => o.id + "" === contact.organisation)[0]}
                orga_edit.contacts = orga_edit.contacts.filter(donnee => donnee.id !== contact.id)
                dispatch(editOrganisation(orga_edit))
            }).catch(error => {
                setIsLoading(false);
            });

    }

    return (
        <div className={"app"}>
            <Navbar/>
            <div className={"content content-fullwidth page_contacts"}>
                <div className={"en-ligne en-tete-page"}>
                    <div className={"titre"}>
                        <h1>Contacts</h1>
                        <p>
                            Coordonnées importantes de {organisations.length > 1 ? "vos organisations" : "votre organisation"}
                        </p>
                    </div>

                    <div className={"mini-sidebar"}>
                        <Bandeau_user />
                    </div>

                </div>

                <div className={"en-ligne en-tete-page"}>
                    <Tableau_helper
                        typeDonnee={Statut_Cellule_Tableau_admin}
                        donnees={donnees}
                        handleEnregistrer={handleEnregistrerStatut}
                        handleSupprimer={handleSupprimerStatut}
                        formatNew={{new:true}}
                    />
                </div>

                <Modal title={"Notes"} opened={notesModal !== null} onClose={() => setNotesModal(null)}>
                    <pre>{notesModal}</pre>

                </Modal>

            </div>

        </div>

    );
}
