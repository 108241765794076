import React, {forwardRef, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Autocomplete, Avatar, ComboboxItem, ComboboxItemGroup,
    Group,
    Modal,
    NativeSelect,
    Select,
    Switch,
    Textarea,
    TextInput
} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Hub} from "../../../types/hub";
import {IconeMagnifyingGlass} from "../../../components/icone/solid_react/magnifying-glass";
import {permissionIsGranted} from "../../../services/permissionsService";
import {IconeTrash} from "../../../components/icone/solid_react/trash";
import {Organisation} from "../../../types/organisation";
import {Cellule} from "../../../types/cellule";
import {IconePlus} from "../../../components/icone/solid_react/plus";
import image_push from "./img_push.jpg"
import {setPasApasEtat} from "../../../store/pasApasSlice";
import {useDispatch} from "react-redux";

export function Cycle_creer({organisation, plats, refresh, cellules_en_cycles, cellules_en_cycles_active}: {
    organisation: Organisation,
    plats: string[],
    refresh: () => void,
    cellules_en_cycles: Cellule[],
    cellules_en_cycles_active: (Cellule | null)[]
}) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);

    const [cellule_selected, setCellule_selected] = useState<string>("null");
    const [plat, setPlat] = useState("");
    const [congelation, setCongelation] = useState(false);

    // useEffect(() => {
    //     let capteurs_en_cycles = organisation.capteurs?.filter(c => c.categorie_abonnement === "Cycle").map(c => c.uuid) ?? [];
    //     let _cells:Cellule[] = [];
    //     organisation.zones?.forEach(z => z.cellules?.filter(c => capteurs_en_cycles.includes(c.extension?.capteur.uuid ?? "aucun_capteur")).map(c => _cells.push(c)));
    //     setCellules_en_cycles(_cells);
    // }, [organisation]);

    function handleEnregistrer() {
        set_btn_loading(true);

        ajax.post("/cellules/" + cellule_selected + "/cycles", {
            "plat": plat,
            "modeCongel": congelation,
        }).then(response => {

            dispatch(setPasApasEtat("modale_cycle_valide"))
            setVue_suivante(true)
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })

    }

    function handleClose() {
        dispatch(setPasApasEtat("modale_cycle_close"))
        handlers.close();
        setTimeout(refresh, 500);
    }

    let cellules_a_afficher: (ComboboxItem)[] = [{label: "-- Cellule", value: "null"}];
    cellules_en_cycles.forEach(c => {
        let en_cours = false;
        cellules_en_cycles_active.forEach(ca => {
            if (!ca) return;
            if (c.id === ca.id) en_cours = true;
        })
        cellules_a_afficher.push({label: c.nom + (en_cours ? " (cycle actif)" : ""), value: c.id+"", disabled: en_cours});
    })

    const [vue_suivante, setVue_suivante] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (opened) dispatch(setPasApasEtat("modale_cycle_opened"))
    }, [opened]);

    return (
        <>

            <button id={"btn_creer_cycle"} onClick={handlers.toggle}><IconePlus /> Créer un cycle</button>

            <Modal opened={opened} onClose={() => vue_suivante ? handleClose() : handlers.close()} title={"Créer un cycle"}>

                {vue_suivante ? (
                    <div className={"vue_suivante_push_cycle"}>
                        <img src={image_push} alt=""/>
                        <p>Merci d'appuyer sur le bouton sur l'enregistreur</p>
                        <button onClick={handleClose}>J'ai compris</button>
                    </div>
                ) : (
                    <div className={"form"} id={"modale_creer_cycle_form"}>

                    <NativeSelect
                            // data={ cellules_en_cycles.map(c => {
                            //     return {label: c.nom, value: c.id+""};
                            // })}
                            data={cellules_a_afficher}
                            id={"modale_creer_cycle_cellule"}
                            label={"Cellule"}
                            value={cellule_selected ?? ""}
                            onChange={e => {
                                setCellule_selected(e.target.value);
                            }}
                        />
                        <Autocomplete
                            data={[...new Set(plats)]}
                            label={"Plat"}
                            id={"modale_creer_cycle_plat"}
                            value={plat ?? ""}
                            onChange={e => {
                                setPlat(e);
                            }}
                        />
                        <div id={"modale_creer_cycle_switch"}>
                            <Switch
                                label={"Mode positif ( 10°C )"}
                                checked={!congelation}
                                onChange={e => {
                                    setCongelation(!e.target.checked);
                                }}
                            />
                            <Switch
                                label={"Mode négatif ( -18°C )"}
                                checked={congelation}
                                onChange={e => {
                                    setCongelation(e.target.checked);
                                }}
                            />
                        </div>



                        <div className={"en-ligne spaceevenly"} id={"modale_creer_cycle_valider"}>

                            <Loading_button is_loading={btn_loading} disabled={cellule_selected === "null" || plat.length === 0}
                                            onClick={handleEnregistrer} icone={"check"}>Démarrer ce cycle</Loading_button>
                        </div>




                    </div>
                )}
                

            </Modal>
        </>

    )
}