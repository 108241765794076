import {Navbar} from "../../../components/navbar/navbar";
import React, {useEffect, useState} from "react";

import './Commentaires.scss'
import ajax from "../../../services/AxiosInterceptor";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import env_config from "../../../env_config";
import {User} from "../../../types/user";
import {setMobile_sidebar_icon} from "../../../store/interfaceSlice";
import {Bandeau_user} from "../../../components/sidebar/bandeau_user/bandeau_user";
import {Tableau_helper, typeDonnee} from "../../../components/tableau/Tableau_helper";
import {IconeLoader} from "../../../components/icone/icone";
import {Combobox, Input, Tooltip, useCombobox} from "@mantine/core";
import {IconeMagnifyingGlass} from "../../../components/icone/solid_react/magnifying-glass";
import {texte_est_inclus} from "../../../services/GestionTextes";
import {IconeCircleInfo} from "../../../components/icone/solid_react/circle-info";
import {permissionIsGranted} from "../../../services/permissionsService";
import {Organisation} from "../../../types/organisation";
import {Loading_div} from "../../../components/loading_div/loading_div";
import {Commentaire_creer} from "./commentaire_creer";
import {Cellule} from "../../../types/cellule";
import {checkExistingToken} from "../../../services/firebase";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {afficherDateTime, afficherHeure, afficherHeureDepuisMinutes, DateTZ} from "../../../services/GestionDates";
import {IconeHourglass} from "../../../components/icone/solid_react/hourglass";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeXmark} from "../../../components/icone/solid_react/xmark";
import {IconeStop} from "../../../components/icone/solid_react/stop";
import {IconeEye} from "../../../components/icone/solid_react/eye";
import {Modale_graphique_export} from "../Mesures/Modales/modale_graphique_export";
import {setPasApasEtat} from "../../../store/pasApasSlice";
import {IconeArrowRotateLeft} from "../../../components/icone/solid_react/arrow-rotate-left";
import {Force_refresh} from "../../../components/force_refresh";
import {IconeQuestion} from "../../../components/icone/solid_react/question";
import {IconePenToSquare} from "../../../components/icone/solid_react/pen-to-square";
import {Commentaire} from "../../../types/commentaire";


export function Commentaires() {

    const [commentaires, setCommentaires] = useState<Commentaire[] | null>(() => null);

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const recherche_orga = queryParams.get('organisation');
    const recherche_cellule = queryParams.get('cellule');
    const forcer_creer = queryParams.get('creer');
    const [forcer_creer_une_fois, setForcer_creer_une_fois] = useState(forcer_creer);

    const [cellule_pour_modale, setCellule_pour_modale] = useState<{cellule:Cellule, debut:DateTZ, fin:DateTZ}|null>(null);

    const [recherche_orga_nom, setRecherche_orga_nom] = useState('');
    const [orga_selectionnee, setOrga_selectionnee] = useState<string>((organisations.filter(o => o.id + "" === recherche_orga)[0] && organisations.filter(o => o.id + "" === recherche_orga)[0].id + "") ?? organisations[0].id + "")

    const orga_selectionnee_full = organisations.find(o => o.id + "" === orga_selectionnee);



    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const [is_loading, setIs_loading] = useState(false);
    const navigate = useNavigate();

    const user = useSelector<RootState, User>(state => state.user)

    const [commentaire_modale_edit, setCommentaire_modale_edit] = useState<Commentaire|null>(null);

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))

    useEffect(() => {
        queryLastInfos(true);
        const interval_clear = setInterval(() => {
            queryLastInfos(false);
        }, env_config.refresh_rate);


        return () => {
            clearInterval(interval_clear);
        }
    }, [orga_selectionnee])


    function queryLastInfos(loadind:boolean) {
        setCommentaire_modale_edit(null);
        setIs_loading(loadind)
        if (!orga_selectionnee) return;
        console.log("orga select : " + orga_selectionnee)
        ajax.get("/organisations/" + orga_selectionnee + "/commentaires").then(response => {
            setCommentaires(response.data.commentaires.sort((a:Commentaire, b:Commentaire) => {
                if (!a.estValiderClient && b.estValiderClient) return -1
                if (a.estValiderClient && !b.estValiderClient) return 1
                if (new Date(a.fin).getTime() === new Date(b.fin).getTime()) return 0
                if (new Date(a.fin).getTime() > new Date(b.fin).getTime()) return -1
                return 1
            }));
            setIs_loading(false);
        }).catch(e => {
            setIs_loading(false)
        })
    }

    useEffect(() => {
        queryLastInfos(true);
    }, [orga_selectionnee]);

    function handleVoir(commentaire:Commentaire)
    {
        // navigate("/cellule/" + commentaire.cellule?.id + "?")

        ajax.get('/cellules/' + commentaire.cellule?.id)
            .then(response => {
                let cellu: Cellule = response.data.cellule;
                let setCellu = () => {
                    setCellule_pour_modale(
                    {
                        cellule: cellu,
                        debut: new DateTZ(commentaire.debut),
                        fin: new DateTZ(commentaire.fin),
                    })
                }
                if (cellule_pour_modale){
                    setCellule_pour_modale(null)
                    setTimeout(() => setCellu, 500);
                } else { setCellu() }
            }).catch(error => {
            console.log(error);
        });




    }

    let typeDonneesCommentaires: typeDonnee[] = [
        {key: "commentaire", key_tab: "id", nom: "ID", noedit: true, edit_display_value: (value, full_row:Commentaire) => full_row.id+""},
        {key: "commentaire", key_tab: "statut", nom: "Statut",
            edit_display_value: (texte, full_row:Commentaire) =>
            {
                if (full_row.estValiderClient)
                {
                    return 'OK'
                }
                else{
                    return "A valider"
                }
            },
            wrapper: (texte, full_row:Commentaire) =>
                <Tooltip label={texte} >
                    <div>
                        {texte === "OK" && <p style={{color: "var(--valid-color)", display:"flex", justifyContent: "center"}}><IconeCheck/></p>}
                        {texte === "A valider" && <p style={{color: "var(--pink-color)", display:"flex", justifyContent: "center"}}><IconeQuestion/></p>}
                    </div>

                </Tooltip>
        },
        {key: "cellule.nom", nom: "Cellule", wrapper: (texte, full_row:Commentaire) => <Link to={"/cellule/" + full_row.cellule?.id} >{full_row.cellule?.nom}</Link>},
        {key: "commentaire", nom: "Commentaire"},
        {key: "debut", nom: "Début", type: "date"},
        {key: "fin", nom: "Fin", type: "date"},
        {key: "afficherDansRapport", nom: "Afficher dans rapport", type: "boolean"},
        {key: "debut", key_tab: "voir", nom: "Voir",
            cacherDansTableSi: (row:Commentaire) => row.fin === null,
            wrapper: (texte, full_row:Commentaire) =>
                <button onClick={() => handleVoir(full_row)}><IconeEye /></button>
        },
        {key: "debut", key_tab: "edit", nom: "Modifier",
            wrapper: (texte, full_row:Commentaire) =>
                <button onClick={() => {
                    setCommentaire_modale_edit(null)
                    setTimeout(() => {setCommentaire_modale_edit(full_row)}, 50)

                }}><IconePenToSquare /></button>
        },
        // {key: "informations", wrapper: (texte:string) => (
        //         <Tooltip label={texte} onClick={() => alert(texte)}>
        //             <p className={"inline-tag"}><IconeEye /></p>
        //         </Tooltip>),
        //     spanClassname: "no-wrap flex_align_center",
        //     desc: "Description affichée au client",
        //     type: "textarea"
        // },
        // {action_editer: true, cacherDansTableSi: (row) => permissionIsGranted(orga_selectionnee_full?.id, "Administrer_commentaire")},
    ]



    const [ouvrir_tuto, setOuvrir_tuto] = useState(false);
    useEffect(() => {
        if (commentaires?.length === 0) setOuvrir_tuto(true);
    }, [commentaires]);




    useEffect(() => {
        if (commentaires) dispatch(setPasApasEtat("page_commentaire_loaded"))
    }, [commentaires]);


    return (
        <div className={"app"}>
            <Navbar/>

            <div className={"content content-fullwidth en-tete-pages"}>


                <div className={"en-ligne en-tete-page"}>
                    <div className={"titre"}>
                        <h1>Vos commentaires</h1>
                        <p>
                            Cette page vous permet de voir et créer les commentaires de votre organisation.
                        </p>
                    </div>

                    <div className={"mini-sidebar"}>
                        <Bandeau_user/>
                    </div>


                </div>


                {organisations && (
                    <div style={{margin: "1rem"}}>
                        <div className={"recherche"}>
                            <Combobox
                                store={combobox}
                                onOptionSubmit={(val) => {
                                    setOrga_selectionnee(val ?? "")
                                    combobox.closeDropdown();
                                }}
                            >
                                <Combobox.Target>
                                    <Input
                                        component="button"
                                        type="button"
                                        pointer
                                        rightSection={<Combobox.Chevron/>}
                                        rightSectionPointerEvents="none"
                                        leftSection={<IconeMagnifyingGlass/>}
                                        className={"paiement_input_recherche"}
                                        onClick={() => combobox.toggleDropdown()}
                                    >
                                        {organisations.find(o => o.id + "" === orga_selectionnee)?.nom
                                            || <Input.Placeholder>Recherche d'une organisation...</Input.Placeholder>}
                                    </Input>
                                </Combobox.Target>

                                <Combobox.Dropdown>
                                    <Combobox.Search
                                        value={recherche_orga_nom}
                                        onChange={(event) => setRecherche_orga_nom(event.currentTarget.value)}
                                        placeholder="Recherche d'une organisation..."
                                    />
                                    <Combobox.Options>
                                        {organisations.filter((o) => texte_est_inclus(o.nom, recherche_orga_nom)).length === 0 &&
                                            <Combobox.Empty>Aucune organisation trouvée.</Combobox.Empty>}
                                        {organisations.filter((o) => texte_est_inclus(o.nom, recherche_orga_nom)).map((o) => (
                                            <Combobox.Option value={o.id + ""} key={o.id}
                                                             className={"en-ligne"}>
                                                {o.nom}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                </Combobox.Dropdown>
                            </Combobox>
                        </div>



                    </div>
                )}

                {orga_selectionnee_full && !is_loading && (
                    <Tableau_helper
                        typeDonnee={typeDonneesCommentaires}
                        donnees={commentaires}
                        // handleEnregistrer={handleEnregistrer}
                        // formatNew={{
                        //     id : -1,
                        //     nom : "",
                        //     informations : "",
                        // }}
                        boutons={
                            <>

                                {permissionIsGranted(orga_selectionnee_full.id, "Administrer_commentaire") && (
                                    <>
                                        <Commentaire_creer commentaire={null}
                                                           organisation={orga_selectionnee_full}
                                                           commentaires_precedents={commentaires?.map(c => c.commentaire) ?? []}
                                                           refresh={() => {
                                                               queryLastInfos(true)
                                                               setForcer_creer_une_fois(null);
                                                           }}
                                                           mode_edit={false}
                                                           forcer={forcer_creer_une_fois}
                                        />

                                    </>
                                )}

                                <button onClick={() => queryLastInfos(true)}><IconeArrowRotateLeft /> Actualiser</button>

                                <button onClick={() => {
                                    setOuvrir_tuto(false);
                                    setTimeout(() => setOuvrir_tuto(true), 500)
                                }}><IconeQuestion /></button>

                            </>
                        }
                        set_columnFilters={recherche_cellule ? [{id: "cellule.nom", value: recherche_cellule}] : undefined}
                    />
                )}

                {is_loading && <Loading_div />}

                {cellule_pour_modale && (
                    <Modale_graphique_export cellule={cellule_pour_modale.cellule} setCellule={() => {
                        setCellule_pour_modale(null)
                    }} modeCache={true} debut={cellule_pour_modale.debut} fin={cellule_pour_modale.fin} />
                )}


                <Force_refresh receiveForceRefresh={() => queryLastInfos(false)} />

                {orga_selectionnee_full && (
                    <Commentaire_creer commentaire={commentaire_modale_edit}
                                       organisation={orga_selectionnee_full}
                                       commentaires_precedents={commentaires?.map(c => c.commentaire) ?? []}
                                       refresh={() => queryLastInfos(true)}
                                       mode_edit={true}
                    />
                )}



            </div>



        </div>

    );
}
