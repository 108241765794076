import {LineChart} from '@mantine/charts';
import React, {useEffect, useRef, useState} from "react";

import './Rapports_pdf.scss'

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {User} from "../../../types/user";
import {useParams} from "react-router-dom";
import {Cellule} from "../../../types/cellule";
import {Mesure} from "../../../types/mesure";
import ajax from "../../../services/AxiosInterceptor";
import {afficherDateTime, DateTZ} from "../../../services/GestionDates";
import {excursion, Graphiques} from "../../../services/Graphiques";
import {Capteur} from "../../../types/capteur";
import {IconeArrowDownToLine} from "../../../components/icone/solid_react/arrow-down-to-line";
import {IconeArrowUp} from "../../../components/icone/solid_react/arrow-up";
import {IconeArrowUpToLine} from "../../../components/icone/solid_react/arrow-up-to-line";
import {changeTimezone} from "../../../store/userSlice";
import {Commentaire} from "../../../types/commentaire";


type cell_complet = { cellule: Cellule, mesures: Mesure[], capteur: Capteur, graph: Graphiques, commentaires: Commentaire[] }


export function Rapports_pdf() {

    const user = useSelector<RootState, User>(state => state.user)

    const [cellules_affiche, setCellules_affiche] = useState<cell_complet[] | null>(null);

    const [loading_fait, setLoading_fait] = useState(0);

    let param_ids = useParams().ids;
    let debut = useParams().debut;
    let fin = useParams().fin;
    let timezone = useParams().timezone;

    const dispatch = useDispatch();

    const cellules = useRef<cell_complet[]>([]);

    useEffect(() => {
        const fetchData = () => {
            if (timezone && timezone != ""
            ) {
                dispatch(changeTimezone({timezone: Number(timezone)}))
            }
            if (param_ids && param_ids != ""
                && debut && debut != ""
                && fin && fin != ""
            ) {
                //Le format dans l'URL doit etre ISO
                let date_debut = debut //new Date(debut).toISOString()
                let date_fin = fin


                let ids: string[] = param_ids.split(",");
                for (let i = 0; i < ids.length; i++) {
                    ajax.get('/cellules/' + ids[i])
                        .then((response) => {
                            let cellu: Cellule = response.data.cellule;

                            ajax.get('/cellules/' + cellu.id + '/mesures' + "?debut=" + date_debut + "&fin=" + date_fin + "&light=true")
                                .then((response) => {
                                    let mesures: Mesure[] = response.data.mesures ?? []

                                    let graph = new Graphiques(cellu, mesures)

                                    let commentaires = response.data.commentaires

                                    // let graph_jsx = <p className={"inline-tag"}>Aucune mesure disponible pour
                                    //     analyse.</p>
                                    // if (graph.mesures && graph.mesures.length > 0) graph_jsx = graph.lineGraph();

                                    ajax.get('/capteurs/' + cellu.extension?.capteur.uuid)
                                        .then(response => {
                                            let received_capteur: Capteur = response.data.capteur;
                                            cellules.current.push({
                                                cellule: cellu,
                                                mesures: mesures,
                                                capteur: received_capteur,
                                                graph: graph,
                                                commentaires: commentaires
                                            })
                                            setLoading_fait(cellu.id)
                                        }).catch(error => {
                                            console.log(error);
                                            throw new Error("AJAX 3 went wrong : " + error);
                                        });


                                }).catch(err => {
                                    console.log(err);
                                    throw new Error("AJAX 2 went wrong : " + err);
                                })


                        }).catch(error => {
                            console.log(error);
                            throw new Error("AJAX 1 went wrong : " + error);
                        });
                }

                // setCellules(_cellules)
            }
            else{
                throw new Error("URL ARGS wrong");
            }
        }

        try{
            fetchData()
        }catch (e) {

            const event = new CustomEvent('PDFGenPageReady', {detail: 'error : ' + e});
            window.dispatchEvent(event);
        }


    }, []);


    useEffect(() => {
        setCellules_affiche(cellules.current)
        if (param_ids) {
            let ids: string[] = param_ids.split(",");
            if (cellules.current.length === ids.length )
            {

                const event = new CustomEvent('PDFGenPageReady', {detail: 'success'});
                window.dispatchEvent(event);
            }
        }
    }, [loading_fait]);

    function get_commentaires_a_afficher(cellule: cell_complet)
    {
        console.log(cellule)
        return cellule.commentaires?.filter(c => c.estValiderClient && c.afficherDansRapport);
    }


    return (
        <div className={"page-rapport-pdf"}>


            {cellules_affiche?.map(cellule => {
                return (
                    <section>
                        <div className={"en-ligne spacebetween"}>
                            <h2>Rapport de {cellule.cellule.sonde_type?.affichage_nom_mesure.toLowerCase()}</h2>
                            <h1>Cellule {cellule.cellule.nom}</h1>
                        </div>


                        <table>
                            <tr>
                                <th>Organisation</th>
                                <th>Zone</th>
                                <th>Nom de l'appareil</th>
                                <th>Réf de l'appareil</th>
                                <th>Modèle de l'appareil</th>
                                <th>Intervalle de mesure</th>
                                {/*Intervalle de mesure : Le 14/10/2024 sur mattermost Yoann m'a dit de mettre l'actuel, qu'on s'en*/}
                                {/*fiche que ce soit pas l'intervalle à la date du rapport.*/}
                            </tr>
                            <tr>
                                <td>{cellule.cellule.organisation.nom}</td>
                                <td>{cellule.cellule.zone.nom}</td>
                                <td>{cellule.cellule.extension?.capteur.nom}</td>
                                <td>{cellule.cellule.extension?.capteur.uuid}</td>
                                <td>{cellule.cellule.extension?.capteur.produit.nom}</td>
                                <td>{cellule.capteur.configurations?.find(c => c.parametre.slug === "refresh_rate")?.valeur ?? 600} secondes</td>
                            </tr>
                        </table>

                        {!cellule.graph.mesures || cellule.graph.mesures.length === 0 ? (
                            <p className={"inline-tag red"}>
                                Aucune mesure n'est disponible sur la période demandée ( du {afficherDateTime(debut)} à {afficherDateTime(fin)} )
                            </p>
                        ) : (
                            <>
                                <table>
                                    <tr>
                                        <th>Début des mesures</th>
                                        <th>Fin des mesures</th>
                                        <th>Nombre de mesures</th>
                                        {cellule.cellule.conditions.filter(a => a.slug < 2).map(anomalie => (
                                            <th>
                                                <div className={"en-ligne"}>
                                                    Seuil {anomalie.slug === 0 && "MIN"} {anomalie.slug === 1 && "MAX"}
                                                    {anomalie.slug === 0 && <IconeArrowDownToLine/>}
                                                    {anomalie.slug === 1 && <IconeArrowUpToLine/>}
                                                    {!["MIN", "MAX"].includes(anomalie.nom) && "("+anomalie.nom+")"}
                                                </div>
                                            </th>
                                        ))}

                                    </tr>
                                    <tr>
                                        <td>{afficherDateTime(cellule.graph.mesures[0].enregistrement)}</td>
                                        <td>{afficherDateTime(cellule.graph.mesures[cellule.graph.mesures.length -1].enregistrement)}</td>
                                        <td>{cellule.graph.mesures.length}</td>
                                        {cellule.cellule.conditions.filter(a => a.slug < 2).map(anomalie => (
                                            <td>{anomalie.valeur_a_comparer}{cellule.cellule.sonde_type?.affichage_suffix} pendant {anomalie.delais} minutes </td>
                                        ))}
                                    </tr>
                                </table>


                                <h3>Analyses des {cellule.cellule.sonde_type?.affichage_nom_mesure.toLowerCase()}s sur la période: </h3>
                                <table>
                                    <tr>
                                        <th>{cellule.cellule.sonde_type?.affichage_nom_mesure} maximale</th>
                                        <th>{cellule.cellule.sonde_type?.affichage_nom_mesure} minimale</th>
                                        <th>{cellule.cellule.sonde_type?.affichage_nom_mesure} moyenne</th>
                                        <th>{cellule.cellule.sonde_type?.affichage_nom_mesure} cinétique moyenne (MKT)</th>
                                    </tr>
                                    <tr>
                                        <td>{cellule.graph.mesure_max?.mesure ?? ""}{cellule.cellule.sonde_type?.affichage_suffix}</td>
                                        <td>{cellule.graph.mesure_min?.mesure ?? ""}{cellule.cellule.sonde_type?.affichage_suffix}</td>
                                        <td>{cellule.graph.mesure_moy ?? ""}{cellule.cellule.sonde_type?.affichage_suffix}</td>
                                        <td>{cellule.graph.calculateMKT(cellule.graph.mesures?.map(m => Number(m.mesure)) ?? []) ?? ""}{cellule.cellule.sonde_type?.affichage_suffix}</td>
                                    </tr>
                                </table>
                                <table>
                                    <tr>
                                        {[5, 10, 25, 75, 90, 95].map(centile => (
                                            <th>{centile}e centile</th>
                                        ))}
                                    </tr>
                                    <tr>
                                        {[5, 10, 25, 75, 90, 95].map(centile => (
                                            <td>{(cellule.graph.mesures_triees && cellule.graph.mesures_triees[Math.round((cellule.graph.mesures_triees.length - 1) / 100 * centile)].mesure+ cellule.cellule.sonde_type?.affichage_suffix ) ?? "Aucune"}</td>
                                        ))}
                                    </tr>
                                </table>



                                {cellule.mesures.length > 0 ? cellule.graph.lineGraph(true) : <p className={"inline-tag"}>Aucune mesure disponible pour analyse.</p>}

                                <div className={"excursions"}>
                                    {cellule.graph.excursions.length === 0 ? (
                                        <p>Aucune excursion sur la période. </p>
                                    ) : (
                                        <>
                                            <h3>Excursions sur la période: </h3>
                                            <table>
                                                <tr>
                                                    <th>Nombre d'excursions</th>
                                                    <th>Durée totale excursions</th>
                                                </tr>
                                                <tr>
                                                    <td>{cellule.graph.excursions.length}</td>
                                                    <td>
                                                        {cellule.graph.excursions.length === 0 ? "0 minutes" : (() => {
                                                            let duree_totale = 0;
                                                            cellule.graph.excursions.map(e => duree_totale += (e.duree < 9 ? 10 : e.duree))
                                                            return (
                                                                <>
                                                                    {duree_totale / 60 > 1 ? Math.floor(duree_totale / 60) + " hrs " : ""}
                                                                    {duree_totale == 0 ? "< 10" : duree_totale % 60 + " minutes"}
                                                                </>
                                                            )
                                                        } )()}
                                                    </td>
                                                </tr>
                                            </table>
                                            {cellule.graph.excursions.length > 0 && (
                                                <>
                                                    <h4>{cellule.graph.excursions.length > 4 ? "4 excursions les plus grandes" : ""}</h4>
                                                    <table>
                                                        <tr>
                                                            <th>Anomalie</th>
                                                            <th>Heure de début</th>
                                                            <th>Heure de fin</th>
                                                            <th>Durée</th>
                                                            <th>Température min / max</th>
                                                            <th>Température moyenne</th>
                                                            <th>MKT</th>
                                                        </tr>
                                                        {/*{(() => {*/}
                                                        {/*    for (let i = 0; i < ; i++) {*/}
                                                        {/*        */}
                                                        {/*    }*/}
                                                        {/*})}*/}
                                                        {[...Array(Math.min(4, cellule.graph.excursions.length))].map((v, index) => {

                                                            let excursions_triees:excursion[] = JSON.parse(JSON.stringify(cellule.graph.excursions))
                                                            excursions_triees = excursions_triees.sort((a, b) => {
                                                                if (a.duree > b.duree) return -1
                                                                return 1
                                                            })

                                                            return (
                                                                <tr key={index}>
                                                                    <td>{excursions_triees[index].anomalie}</td>
                                                                    <td>{afficherDateTime(excursions_triees[index].debut)}</td>
                                                                    <td>{afficherDateTime(excursions_triees[index].fin)}</td>
                                                                    <td>
                                                                        {excursions_triees[index].duree / 60 > 1 ? Math.floor(excursions_triees[index].duree / 60) + " hrs " : ""}
                                                                        {excursions_triees[index].duree == 0 ? "< 10" : excursions_triees[index].duree % 60} minutes
                                                                    </td>
                                                                    <td>
                                                                        {excursions_triees[index].min}{cellule.cellule.sonde_type?.affichage_suffix} / {excursions_triees[index].max}{cellule.cellule.sonde_type?.affichage_suffix}
                                                                    </td>
                                                                    <td>
                                                                        {cellule.graph.calculateMoyenne(excursions_triees[index].mesures)}{cellule.cellule.sonde_type?.affichage_suffix}
                                                                    </td>
                                                                    <td>
                                                                        {cellule.graph.calculateMKT(excursions_triees[index].mesures)}{cellule.cellule.sonde_type?.affichage_suffix}
                                                                    </td>
                                                                </tr>
                                                            )})}


                                                    </table>
                                                </>
                                            )}
                                        </>
                                    )}

                                </div>
                                <div className={"commentaires"}>
                                    {get_commentaires_a_afficher(cellule).length > 0 && (
                                        <>
                                            <h3>Commentaires sur la période: </h3>
                                            <table>
                                                <tr>
                                                    <th>Début</th>
                                                    <th>Fin</th>
                                                    <th>Commentaire</th>
                                                </tr>
                                                {get_commentaires_a_afficher(cellule).map(commentaire => {

                                                    return (
                                                        <tr>
                                                            <td>{afficherDateTime(commentaire.debut)}</td>
                                                            <td>{afficherDateTime(commentaire.fin)}</td>
                                                            <td>{commentaire.commentaire}</td>
                                                        </tr>
                                                    )
                                                })}

                                            </table>
                                        </>
                                    )}

                                </div>
                            </>
                        )}


                    </section>
                )
            })}


        </div>

    );
}
