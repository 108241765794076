import React, {forwardRef, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Autocomplete, Avatar, Group, Modal, Select, Textarea, TextInput} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Hub} from "../../../types/hub";
import {IconeMagnifyingGlass} from "../../../components/icone/solid_react/magnifying-glass";
import {permissionIsGranted} from "../../../services/permissionsService";
import {IconeTrash} from "../../../components/icone/solid_react/trash";

export function Modale_editer_hub({hub, setEditedHub, hubs, setHubs}: { hub: Hub|null, setEditedHub:(hubs:Hub|null)=>void, hubs:Hub[]|null, setHubs:(hubs:Hub[])=>void }) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);


    const [tmp_hub, setTmp_hub] = useState<Hub|null>(hub);

    function handleEnregistrer() {
        set_btn_loading(true);
        if (!tmp_hub){
            alert("Erreur, le hub est null.")
            return;
        }
        ajax.put("/hubs/" + tmp_hub.uuid, {
            "nom": tmp_hub.nom,
            "modele": tmp_hub.modele,
            "version_logicielle": tmp_hub.version_logicielle,
            "notes": tmp_hub.notes,
            "notes_admin": tmp_hub.notes_admin,
        }).then(response => {
            let hubs_maj = hubs?.map(h => {
                if (h.uuid === tmp_hub.uuid)
                {
                    return tmp_hub
                }
                return h;
            })
            if (hubs_maj)
            {
                setHubs(hubs_maj);
                handlers.close();
            }
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })

    }


    useEffect(() => {
        if (hub) handlers.open()
        else handlers.close()
        setTmp_hub(hub)
    }, [hub])

    const [modeles, setModeles] = useState<{[index: string]: string[] }>({});
    useEffect(() => {
        if (permissionIsGranted(hub?.organisation?.id ?? -1, "ADMIN") && opened) {
            ajax.get("/hub/modeles").then(response => {
                setModeles(response.data.modeles);
            }).catch(e => {
            })
        }

    }, [opened])

    function retirerDeLorganisation()
    {
        if (!hub) return;
        if (window.confirm("Êtes-vous sûr de vouloir retirer cet appareil de votre organisation ?"))
        {
            ajax.put("/hubs/" + hub.uuid, {
                "organisation_id": null,
            }).then(response => {
                let hubs_maj = hubs?.filter(h => h.uuid !== hub.uuid)
                if (hubs_maj)
                {
                    setHubs(hubs_maj);
                    handlers.close();
                }
                set_btn_loading(false);
            }).catch(error => {
                set_btn_loading(false);
            })
        }
    }

    function supprimerDeLaPlateforme()
    {
        if (!hub) return;
        if (window.confirm("ATTENTION ACTION IRREVERSIBLE!\nEtes vous sûr de retirer définitivement ce hub de la plateforme ?"))
        {
            ajax.delete("/hubs/" + hub.uuid).then(response => {
                let hubs_maj = hubs?.filter(h => h.uuid !== hub.uuid)
                if (hubs_maj)
                {
                    setHubs(hubs_maj);
                    handlers.close();
                }
                set_btn_loading(false);
            }).catch(error => {
                set_btn_loading(false);
            })
        }
    }

    if (!tmp_hub) return <></>

    return (
        <>
            <Modal opened={opened} onClose={() => {
                handlers.close()
                setEditedHub(null);
            }} title={"Modifier le hub " + tmp_hub.uuid}>
                <div className={"form"}>

                    <TextInput
                        label={"Nom"}
                        value={tmp_hub.nom}
                        onChange={e => {
                            let _th = {...tmp_hub};
                            _th.nom = e.target.value
                            setTmp_hub(_th)
                        }}
                    />

                    {permissionIsGranted(hub?.organisation?.id ?? -1, "ADMIN") && (
                        <>
                            <Autocomplete
                                label={"Modèle"}
                                value={tmp_hub.modele}
                                leftSection={<IconeMagnifyingGlass />}
                                onChange={e => {
                                    let _th = {...tmp_hub};
                                    _th.modele = e
                                    setTmp_hub(_th)
                                }}
                                data={Object.keys(modeles)}
                                required
                            />
                            <Autocomplete
                                label={"Version logicielle"}
                                value={tmp_hub.version_logicielle}
                                leftSection={<IconeMagnifyingGlass />}
                                onChange={e => {
                                    let _th = {...tmp_hub};
                                    _th.version_logicielle = e
                                    setTmp_hub(_th)
                                }}
                                data={modeles[tmp_hub.modele] ?? []}
                                required
                            />
                        </>
                    )}

                    <Textarea
                        label={"Notes"}
                        description={"Notes libres"}
                        value={tmp_hub.notes}
                        onChange={e => {
                            let _th = {...tmp_hub};
                            _th.notes = e.target.value
                            setTmp_hub(_th)
                        }}
                        autosize
                        minRows={2}
                    />

                    {permissionIsGranted(hub?.organisation?.id ?? -1, "ADMIN") && (
                        <Textarea
                            label={"Notes Administrateur"}
                            description={"Seulement visible par BHC/Freshtime"}
                            value={tmp_hub.notes_admin}
                            onChange={e => {
                                let _th = {...tmp_hub};
                                _th.notes_admin = e.target.value
                                setTmp_hub(_th)
                            }}
                            autosize
                            minRows={2}
                        />
                    )}


                    {hub?.vpn_ip && (
                        <p>Adresse IP du hub sur le VPN : <a href={"http://" + hub.vpn_ip}>{hub.vpn_ip}</a></p>
                    )}

                    <div className={"en-ligne spaceevenly"}>

                        <button onClick={retirerDeLorganisation} className={"sec rouge"}><IconeTrash /> Retirer de l'organisation</button>

                        <Loading_button is_loading={btn_loading}
                                        onClick={handleEnregistrer} icone={"check"}>Enregistrer</Loading_button>
                    </div>

                    {permissionIsGranted(undefined, "ADMIN") && (
                        <div className={"en-ligne spaceevenly"}>

                            <button onClick={supprimerDeLaPlateforme} className={"sec rouge"}><IconeTrash /> ADMIN : Supprimer le hub de la plateforme</button>

                        </div>
                    )}




                </div>

            </Modal>
        </>

    )
}